import * as types from 'redux/actions/types';

const INITIAL_STATE = {
  category: '',
  producers: '',
  locations: '',
  stocked: '',
  specifications: {},
  paginate: { page: 1, limit: 10 }
};

const _updateQuery = (state, action) => {
  const { queryName, value } = action.payload;
  if (queryName === 'specifications')
    return { ...state, specifications: { ...state.specifications, ...value } };
  return { ...state, [queryName]: value };
};

export const productsQueryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.updateProductsQuery:
      return _updateQuery(state, action);
    case types.resetProductsForm:
      return { ...INITIAL_STATE, category: state.category };
    default:
      return state;
  }
};
