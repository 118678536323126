import * as types from 'redux/actions/types';
import { deleteData } from '../utils';

const INITIAL_STATE = {
  data: [],
  count: 0
};

export const newsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.getNewsSuccess:
      const { data, count } = action.payload;
      return { ...state, data, count };
    case types.deleteNewsSuccess:
      return deleteData(state, action.payload);
    default:
      return state;
  }
};
