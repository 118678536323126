const _getTypeValue = (method, resource) => ({
  request: `${method}_${resource}_REQUEST`,
  success: `${method}_${resource}_SUCCESS`,
  failure: `${method}_${resource}_FAILURE`
});

export const TYPES = {
  // *** REVIEWS ***
  reviews: {
    GET: _getTypeValue('GET', 'REVIEWS'),
    GET_ONE: _getTypeValue('GET', 'REVIEW'),
    DELETE: _getTypeValue('DELETE', 'REVIEW'),
    UPDATE: _getTypeValue('UPDATE', 'REVIEW'),
    CREATE: _getTypeValue('CREATE', 'REVIEW')
  },
  // *** MESSAGES ***
  messages: {
    GET: _getTypeValue('GET', 'MESSAGES'),
    GET_ONE: _getTypeValue('GET', 'MESSAGE'),
    DELETE: _getTypeValue('DELETE', 'MESSAGE'),
    UPDATE: _getTypeValue('UPDATE', 'MESSAGE'),
    CREATE: _getTypeValue('CREATE', 'MESSAGE')
  },
  // *** CONTENTS ***
  contents: {
    GET: _getTypeValue('GET', 'CONTENTS'),
    GET_ONE: _getTypeValue('GET', 'CONTENT'),
    DELETE: _getTypeValue('DELETE', 'CONTENT'),
    UPDATE: _getTypeValue('UPDATE', 'CONTENT'),
    CREATE: _getTypeValue('CREATE', 'CONTENT')
  },
  // *** INQUIRIES ***
  inquiries: {
    GET: _getTypeValue('GET', 'INQUIRIES'),
    GET_ONE: _getTypeValue('GET', 'INQUIRY'),
    DELETE: _getTypeValue('DELETE', 'INQUIRY'),
    UPDATE: _getTypeValue('UPDATE', 'INQUIRY'),
    CREATE: _getTypeValue('CREATE', 'INQUIRY')
  },
  // *** Credit Sales ***
  credits: {
    GET: _getTypeValue('GET', 'CREDIT_SALES'),
    GET_ONE: _getTypeValue('GET', 'CREDIT_SALE'),
    DELETE: _getTypeValue('DELETE', 'CREDIT_SALE'),
    UPDATE: _getTypeValue('UPDATE', 'CREDIT_SALE'),
    CREATE: _getTypeValue('CREATE', 'CREDIT_SALE')
  },
  // *** FACTORIES ***
  producers: {
    GET: _getTypeValue('GET', 'PRODUCERS'),
    GET_ONE: _getTypeValue('GET', 'PRODUCER'),
    DELETE: _getTypeValue('DELETE', 'PRODUCER'),
    UPDATE: _getTypeValue('UPDATE', 'PRODUCER'),
    CREATE: _getTypeValue('CREATE', 'PRODUCER')
  }
};

export const updateContentQsRequest = 'UPDATE_CONTENT_QS_REQUEST';
export const updateContentQsSuccess = 'UPDATE_CONTENT_QS_SUCCESS';
export const updateContentQsFailure = 'UPDATE_CONTENT_QS_FAILURE';

export const updateContentCatRequest = 'UPDATE_CONTENT_CAT_REQUEST';
export const updateContentCatSuccess = 'UPDATE_CONTENT_CAT_SUCCESS';
export const updateContentCatFailure = 'UPDATE_CONTENT_CAT_FAILURE';

export const updateContentCallNumRequest = 'UPDATE_CONTENT_CALL_NUM_REQUEST';
export const updateContentCallNumSuccess = 'UPDATE_CONTENT_CALL_NUM_SUCCESS';
export const updateContentCallNumFailure = 'UPDATE_CONTENT_CALL_NUM_FAILURE';

export const paginateParams = 'PAGINATE_PARAMS';
export const deleteRebarWeightFile = 'DELETE_REBAR_WEIGHT_FILE';

export const loginUserRequest = 'LOG_IN_USER_REQUEST';
export const loginUserSuccess = 'LOG_IN_USER_SUCCESS';
export const loginUserFailure = 'LOG_IN_USER_FAILURE';

export const logoutUserRequest = 'LOG_OUT_USER_REQUEST';
export const logoutUserSuccess = 'LOG_OUT_USER_SUCCESS';
export const logoutUserFailure = 'LOG_OUT_USER_FAILURE';

export const addQueryParams = 'ADD_QUERY_PARAMS';
export const removeQueryParams = 'REMOVE_QUERY_PARAMS';
export const setQueryPaginate = 'SET_QUERY_PAGINATE';
export const setPatternCategoryId = 'SET_PATTERN_CATEGORY_ID';
export const updateProductsQuery = 'UPDATE_PRODUCTS_QUERY';
export const resetProductsForm = 'RESET_PRODUCTS_FORM';

export const addPatternToListRequest = 'ADD_PATTERN_TO_LIST_REQUEST';
export const addPatternToListSuccess = 'ADD_PATTERN_TO_LIST_SUCCESS';
export const addPatternToListFailure = 'ADD_PATTERN_TO_LIST_FAILURE';

export const addSellerRequest = 'ADD_SELLER_REQUEST';
export const addSellerSuccess = 'ADD_SELLER_SUCCESS';
export const addSellerFailure = 'ADD_SELLER_FAILURE';

export const addStaffRequest = 'ADD_STAFF_REQUEST';
export const addStaffSuccess = 'ADD_STAFF_SUCCESS';
export const addStaffFailure = 'ADD_STAFF_FAILURE';

export const addNewsRequest = 'ADD_NEWS_REQUEST';
export const addNewsSuccess = 'ADD_NEWS_SUCCESS';
export const addNewsFailure = 'ADD_NEWS_FAILURE';

export const addProducerRequest = 'ADD_PRODUCER_REQUEST';
export const addProducerSuccess = 'ADD_PRODUCER_SUCCESS';
export const addProducerFailure = 'ADD_PRODUCER_FAILURE';

export const addProductPatternsRequest = 'ADD_PRODUCT_PATTERNS_REQUEST';
export const addProductPatternsSuccess = 'ADD_PRODUCT_PATTERNS_SUCCESS';
export const addProductPatternsFailure = 'ADD_PRODUCT_PATTERNS_FAILURE';

export const addFactoryPriceRequest = 'ADD_FACTORY_PRICE_REQUEST';
export const addFactoryPriceSuccess = 'ADD_FACTORY_PRICE_SUCCESS';
export const addFactoryPriceFailure = 'ADD_FACTORY_PRICE_FAILURE';

export const editProducerRequest = 'EDIT_PRODUCER_REQUEST';
export const editProducerSuccess = 'EDIT_PRODUCER_SUCCESS';
export const editProducerFailure = 'EDIT_PRODUCER_FAILURE';

export const getNewsRequest = 'GET_NEWS_REQUEST';
export const getNewsSuccess = 'GET_NEWS_SUCCESS';
export const getNewsFailure = 'GET_NEWS_FAILURE';

export const editProductPatternRequest = 'EDIT_PRODUCT_PATTERNS_REQUEST';
export const editProductPatternSuccess = 'EDIT_PRODUCT_PATTERNS_SUCCESS';
export const editProductPatternFailure = 'EDIT_PRODUCT_PATTERNS_FAILURE';

export const getLoadingAreasRequest = 'GET_LOADING_AREAS_REQUEST';
export const getLoadingAreasSuccess = 'GET_LOADING_AREAS_SUCCESS';
export const getLoadingAreasFailure = 'GET_LOADING_AREAS_FAILURE';

export const getCategoriesRequest = 'GET_CATEGORIES_REQUEST';
export const getCategoriesSuccess = 'GET_CATEGORIES_SUCCESS';
export const getCategoriesFailure = 'GET_CATEGORIES_FAILURE';

export const getProducersByCatRequest = 'GET_PRODUCERS_BY_CAT_REQUEST';
export const getProducersByCatSuccess = 'GET_PRODUCERS_BY_CAT_SUCCESS';
export const getProducersByCatFailure = 'GET_PRODUCERS_BY_CAT_FAILURE';

export const getPProductsRequest = 'GET_P_PRODUCTS_REQUEST';
export const getPProductsSuccess = 'GET_P_PRODUCTS_SUCCESS';
export const getPProductsFailure = 'GET_P_PRODUCTS_FAILURE';

export const getProducersRequest = 'GET_PRODUCERS_REQUEST';
export const getProducersSuccess = 'GET_PRODUCERS_SUCCESS';
export const getProducersFailure = 'GET_PRODUCERS_FAILURE';

export const getPatternsRequest = 'GET_PATTERNS_REQUEST';
export const getPatternsSuccess = 'GET_PATTERNS_SUCCESS';
export const getPatternsFailure = 'GET_PATTERNS_FAILURE';

export const getSiteUsersRequest = 'GET_SITE_USERS_REQUEST';
export const getSiteUsersSuccess = 'GET_SITE_USERS_SUCCESS';
export const getSiteUsersFailure = 'GET_SITE_USERS_FAILURE';

export const getStaffsRequest = 'GET_STAFFS_REQUEST';
export const getStaffsSuccess = 'GET_STAFFS_SUCCESS';
export const getStaffsFailure = 'GET_STAFFS_FAILURE';

export const getFactoriesPricesRequest = 'GET_FACTORIES_PRICES_REQUEST';
export const getFactoriesPricesSuccess = 'GET_FACTORIES_PRICES_SUCCESS';
export const getFactoriesPricesFailure = 'GET_FACTORIES_PRICES_FAILURE';

export const deleteFactoryPriceRequest = 'DELETE_FACTORY_PRICE_REQUEST';
export const deleteFactoryPriceSuccess = 'DELETE_FACTORY_PRICE_SUCCESS';
export const deleteFactoryPriceFailure = 'DELETE_FACTORY_PRICE_FAILURE';

export const deleteNewsRequest = 'DELETE_NEWS_REQUEST';
export const deleteNewsSuccess = 'DELETE_NEWS_SUCCESS';
export const deleteNewsFailure = 'DELETE_NEWS_FAILURE';

export const deletePatternRequest = 'DELETE_PATTERN_REQUEST';
export const deletePatternSuccess = 'DELETE_PATTERN_SUCCESS';
export const deletePatternFailure = 'DELETE_PATTERN_FAILURE';

export const deleteProductRequest = 'DELETE_PRODUCT_REQUEST';
export const deleteProductSuccess = 'DELETE_PRODUCT_SUCCESS';
export const deleteProductFailure = 'DELETE_PRODUCT_FAILURE';

export const deleteProducerRequest = 'DELETE_PRODUCER_REQUEST';
export const deleteProducerSuccess = 'DELETE_PRODUCER_SUCCESS';
export const deleteProducerFailure = 'DELETE_PRODUCER_FAILURE';

export const deleteStaffRequest = 'DELETE_STAFF_REQUEST';
export const deleteStaffSuccess = 'DELETE_STAFF_SUCCESS';
export const deleteStaffFailure = 'DELETE_STAFF_FAILURE';

export const updateStaffRequest = 'UPDATE_STAFF_REQUEST';
export const updateStaffSuccess = 'UPDATE_STAFF_SUCCESS';
export const updateStaffFailure = 'UPDATE_STAFF_FAILURE';

export const updatePatternRequest = 'UPDATE_PATTERN_REQUEST';
export const updatePatternSuccess = 'UPDATE_PATTERN_SUCCESS';
export const updatePatternFailure = 'UPDATE_PATTERN_FAILURE';

export const updateProductRequest = 'UPDATE_PRODUCT_REQUEST';
export const updateProductSuccess = 'UPDATE_PRODUCT_SUCCESS';
export const updateProductFailure = 'UPDATE_PRODUCT_FAILURE';

export const updateProductPriceRequest = 'UPDATE_PRODUCT_PRICE_REQUEST';
export const updateProductPriceSuccess = 'UPDATE_PRODUCT_PRICE_SUCCESS';
export const updateProductPriceFailure = 'UPDATE_PRODUCT_PRICE_FAILURE';

export const updateFactoryDataRequest = 'UPDATE_FACTORY_DATA_REQUEST';
export const updateFactoryDataSuccess = 'UPDATE_FACTORY_DATA_SUCCESS';
export const updateFactoryDataFailure = 'UPDATE_FACTORY_DATA_FAILURE';

export const updateFactoryCurrentPriceRequest =
  'UPDATE_FACTORY_CURRENT_PRICE_REQUEST';
export const updateFactoryCurrentPriceSuccess =
  'UPDATE_FACTORY_CURRENT_PRICE_SUCCESS';
export const updateFactoryCurrentPriceFailure =
  'UPDATE_FACTORY_CURRENT_PRICE_FAILURE';

export const uploadPhotosRequest = 'UPLOAD_PHOTOS_REQUEST';
export const uploadPhotosSuccess = 'UPLOAD_PHOTOS_SUCCESS';
export const uploadPhotosFailure = 'UPLOAD_PHOTOS_FAILURE';

export const groupEditProductsRequest = 'GROUP_EDIT_PRODUCTS_REQUEST';
export const groupEditProductsSuccess = 'GROUP_EDIT_PRODUCTS_SUCCESS';
export const groupEditProductsFailure = 'GROUP_EDIT_PRODUCTS_FAILURE';

export const searchPatternsRequest = 'SEARCH_PATTERNS_REQUEST';
export const searchPatternsSuccess = 'SEARCH_PATTERNS_SUCCESS';
export const searchPatternsFailure = 'SEARCH_PATTERNS_FAILURE';

export const searchUserProductsRequest = 'SEARCH_USER_PRODUCTS_REQUEST';
export const searchUserProductsSuccess = 'SEARCH_USER_PRODUCTS_SUCCESS';
export const searchUserProductsFailure = 'SEARCH_USER_PRODUCTS_FAILURE';

export const getAnnualCallReportsRequest = 'GET_ANNUAL_CALLS_REQUEST';
export const getAnnualCallReportsSuccess = 'GET_ANNUAL_CALLS_SUCCESS';
export const getAnnualCallReportsFailure = 'GET_ANNUAL_CALLS_FAILURE';

export const getAnalyticsReportRequest = 'GET_ANALYTICS_REPORT_REQUEST';
export const getAnalyticsReportSuccess = 'GET_ANALYTICS_REPORT_SUCCESS';
export const getAnalyticsReportFailure = 'GET_ANALYTICS_REPORT_FAILURE';

export const getTotalAnalyticsRequest = 'GET_TOTAL_ANALYTICS_REQUEST';
export const getTotalAnalyticsSuccess = 'GET_TOTAL_ANALYTICS_SUCCESS';
export const getTotalAnalyticsFailure = 'GET_TOTAL_ANALYTICS_FAILURE';

export const getCallReportsRequest = 'GET_CALL_REPORTS_REQUEST';
export const getCallReportsSuccess = 'GET_CALL_REPORTS_SUCCESS';
export const getCallReportsFailure = 'GET_CALL_REPORTS_FAILURE';

export const addCallReportRequest = 'ADD_CALL_REPORT_REQUEST';
export const addCallReportSuccess = 'ADD_CALL_REPORT_SUCCESS';
export const addCallReportFailure = 'ADD_CALL_REPORT_FAILURE';

export const updateCallReportRequest = 'UPDATE_CALL_REPORT_REQUEST';
export const updateCallReportSuccess = 'UPDATE_CALL_REPORT_SUCCESS';
export const updateCallReportFailure = 'UPDATE_CALL_REPORT_FAILURE';

export const deleteCallReportRequest = 'DELETE_CALL_REPORT_REQUEST';
export const deleteCallReportSuccess = 'DELETE_CALL_REPORT_SUCCESS';
export const deleteCallReportFailure = 'DELETE_CALL_REPORT_FAILURE';

export const getProductsStatsRequest = 'GET_PRODUCTS_STATS_REQUEST';
export const getProductsStatsSuccess = 'GET_PRODUCTS_STATS_SUCCESS';
export const getProductsStatsFailure = 'GET_PRODUCTS_STATS_FAILURE';
