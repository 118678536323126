import * as types from 'redux/actions/types';

export const contentsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TYPES.contents.GET.success:
      return action.payload.data.reduce(
        (acc, item) => ({ ...acc, [item.referTo]: item }),
        {}
      );
    case types.TYPES.contents.GET_ONE.success:
      return {
        ...state,
        [action.referTo]: action.payload
      };
    case types.updateContentQsSuccess:
      return {
        ...state,
        [action.referTo]: {
          ...state[action.referTo],
          questions: action.payload
        }
      };
    case types.updateContentCatSuccess:
      return {
        ...state,
        [action.referTo]: {
          ...state[action.referTo],
          categories: action.payload
        }
      };
    default:
      return state;
  }
};
