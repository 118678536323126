import * as types from 'redux/actions/types';
import { deleteData } from '../utils';

const INITIAL_STATE = {
  data: [],
  count: 0,
  factory: null
};

export const factoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.TYPES.producers.GET.success:
      return { ...state, ...action.payload };
    case types.TYPES.producers.GET_ONE.success:
      return { ...state, factory: action.payload };
    case types.TYPES.producers.DELETE.success:
      return deleteData(state, action.payload);
    case types.TYPES.producers.UPDATE.success:
      return {
        ...state,
        data: state.data.map(el =>
          el._id === action.payload._id ? { ...el, ...action.payload } : el
        )
      };
    case types.updateContentQsSuccess:
      if (action.referTo === 'factory' && action.producerId) {
        return {
          ...state,
          factory: {
            ...state.factory,
            ...(action.categoryId
              ? {
                  products: {
                    ...state.factory.products,
                    [action.categoryId]: {
                      ...state.factory.products[action.categoryId],
                      questions: action.payload
                    }
                  }
                }
              : { questions: action.payload })
          }
        };
      }
      return state;
    default:
      return state;
  }
};
