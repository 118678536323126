import React from 'react';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import CollectionsBookmarkOutlinedIcon from '@material-ui/icons/CollectionsBookmarkOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';

import ListItem from '../ListItem';

const UserItems = () => {
  return (
    <React.Fragment>
      {userLinks.map((link, i) => (
        <ListItem {...link} key={i}>
          <link.icon />
        </ListItem>
      ))}
    </React.Fragment>
  );
};

const userLinks = [
  {
    text: 'داشبورد',
    icon: DashboardOutlinedIcon,
    linkTo: '/dashboard',
    isActive: false
  },
  {
    text: 'محصولات',
    icon: CollectionsBookmarkOutlinedIcon,
    linkTo: '/products',
    isActive: false
  },
  {
    text: 'پیام‌ها',
    icon: MailOutlineOutlinedIcon,
    linkTo: '/messages',
    isActive: false
  },
  {
    text: 'پروفایل',
    icon: AccountBoxOutlinedIcon,
    linkTo: '/profile',
    isActive: false
  }
];

export default UserItems;
