import * as types from 'redux/actions/types';

const INITIAL_STATE = {
  page: 0,
  limit: 10
};

export const paginateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.paginateParams:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
