import * as types from 'redux/actions/types';
import { deleteData, updateData } from '../utils';

const INITIAL_STATE = {
  data: [],
  loadingAreas: [],
  count: 0,
  stats: []
};

export const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.getProductsStatsSuccess:
      return {
        ...state,
        stats: action.payload.stats,
        count: action.payload.total
      };
    case types.getLoadingAreasSuccess:
      return { ...state, loadingAreas: action.payload };
    case types.searchUserProductsSuccess:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count
      };
    case types.deleteProductSuccess:
      return deleteData(state, action.payload);
    case types.updateProductSuccess:
      return updateData(state, action.payload);
    case types.updateProductPriceSuccess:
      const { loadingArea: location } = action.payload;
      let newItem = { ...action.payload };
      if (location) newItem = { ...newItem, loadingArea: location.city };

      return updateData(state, newItem);
    default:
      return state;
  }
};
