import { toastr } from 'react-redux-toastr';
import pluralize from 'pluralize';

import asreahanApi from 'api/asreahanApi';
import errorHandler from './errorHandler';
import { TYPES } from './types';
// GET ALL RESOURCES
export const getAll =
  resource =>
  (params = {}) => {
    return async dispatch => {
      const { request, success, failure } = TYPES[resource].GET;
      try {
        dispatch({ type: request });
        const { data } = await asreahanApi.get(`/${resource}`, { params });
        dispatch({
          type: success,
          payload: { data: data.data[resource], count: data.totalDocs }
        });
      } catch (error) {
        errorHandler(error, dispatch, failure);
      }
    };
  };

// GET ONE DOCUMENT
export const getOne =
  resource =>
  (itemId, params = {}) => {
    return async dispatch => {
      const { request, success, failure } = TYPES[resource].GET_ONE;
      try {
        dispatch({ type: request });
        const { data } = await asreahanApi.get(`/${resource}/${itemId}`, {
          params
        });
        dispatch({
          type: success,
          payload: data.data[pluralize.singular(resource)]
        });
      } catch (error) {
        errorHandler(error, dispatch, failure);
      }
    };
  };

// UPDATE ONE DOCUMENT
export const updateOne =
  resource =>
  (itemId, updateFields = {}, onSuccess) => {
    return async dispatch => {
      const { request, success, failure } = TYPES[resource].UPDATE;
      try {
        dispatch({ type: request });
        const { data } = await asreahanApi.patch(
          `/${resource}/${itemId}`,
          updateFields
        );
        dispatch({
          type: success,
          payload: data.data[pluralize.singular(resource)]
        });

        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess();
        }
        toastr.success('Success', 'اطلاعات با موفقیت آپدیت شد');
      } catch (error) {
        errorHandler(error, dispatch, failure);
      }
    };
  };

// DELETE ONE DOCUMENT
export const deleteOne = resource => itemId => {
  return async dispatch => {
    const { request, success, failure } = TYPES[resource].DELETE;
    try {
      dispatch({ type: request });
      await asreahanApi.delete(`/${resource}/${itemId}`);
      dispatch({
        type: success,
        payload: itemId
      });
    } catch (error) {
      errorHandler(error, dispatch, failure);
    }
  };
};

// CREATE ONE DOCUMENT
export const createOne =
  resource =>
  (formValue = {}, cb) => {
    return async dispatch => {
      const { request, success, failure } = TYPES[resource].CREATE;
      try {
        dispatch({ type: request });
        const { data } = await asreahanApi.post(`/${resource}`, formValue);
        const payload = data.data[pluralize.singular(resource)];
        dispatch({
          type: success,
          payload
        });
        if (cb && typeof cb === 'function') cb(payload);
        toastr.success('Success', 'اطلاعات با موفقیت ذخیره شد');
      } catch (error) {
        errorHandler(error, dispatch, failure);
      }
    };
  };
