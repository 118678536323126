import { toastr } from 'react-redux-toastr';
import asreahanApi from 'api/asreahanApi';
import errorHandler from '../errorHandler';
import * as types from '../types';

export const addNews = data => {
  return async dispatch => {
    try {
      dispatch({ type: types.addNewsRequest });
      await asreahanApi.post('/news', data);
      dispatch({
        type: types.addNewsSuccess
      });
      dispatch(getNews())
      toastr.success('Success', 'خبر جدید با موفقیت ایجاد شد');
    } catch (error) {
      errorHandler(error, dispatch, types.addNewsFailure);
    }
  };
};

export const getNews = (params = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: types.getNewsRequest });
      const { data } = await asreahanApi.get('/news', {
        params: { select: '-updatedAt,-createdAt,-__v', ...params }
      });
      dispatch({
        type: types.getNewsSuccess,
        payload: { data: data.data.news, count: data.totalDocs }
      });
    } catch (error) {
      errorHandler(error, dispatch, types.getNewsFailure);
    }
  };
};

export const deleteNews = id => {
  return async dispatch => {
    try {
      dispatch({ type: types.deleteNewsRequest });
      await asreahanApi.delete(`/news/${id}`);
      dispatch({
        type: types.deleteNewsSuccess,
        payload: id
      });
    } catch (error) {
      errorHandler(error, dispatch, types.deleteNewsFailure);
    }
  };
};
