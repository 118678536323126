import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { lazyPreload } from 'utils/helpers';
import MaterialRtl from 'components/HOC/MaterialRtl';
import MainLayout from 'layouts/main';
import ProtectedRoute from './Protected';
import { routes as adminRoutes } from './admin';
import history from '../history';

const HomePage = lazyPreload(() => import('pages/home'));
const LoginPage = lazyPreload(() => import('pages/login'));
const UserProfile = lazyPreload(() => import('pages/user/profile'));
const Unauthorized = lazyPreload(() => import('pages/403'));
const NotFound = lazyPreload(() => import('pages/404'));

const Routes = ({ currentUser }) => {
  return (
    <Router history={history}>
      <React.Suspense
        fallback={<h1 className="h-warning">...در حال بارگذاری صفحه</h1>}
      >
        <MaterialRtl>
          <MainLayout>
            <Switch>
              <Redirect exact from="/" to="/login" />
              {/* LOGIN PAGE */}
              <Route path="/login" exact>
                {currentUser ? <Redirect to="/home" /> : <LoginPage />}
              </Route>
              {/* HOME PAGE */}
              <ProtectedRoute
                exact
                user={currentUser}
                path="/home"
                component={HomePage}
              />
              {/* ADMIN PAGES */}
              {adminRoutes.map(route => (
                <ProtectedRoute
                  exact
                  user={currentUser}
                  adminRoute
                  key={route.path}
                  {...route}
                />
              ))}
              {/* Sellers PAGES */}
              <ProtectedRoute
                exact
                user={currentUser}
                path="/profile"
                component={UserProfile}
              />

              {/* 403 PAGE */}
              <Route path="/unauthorized" exact>
                <Unauthorized />
              </Route>
              {/* 404 PAGE */}
              <Route path="/404" exact>
                <NotFound />
              </Route>
              <Redirect to="/404" />
            </Switch>
          </MainLayout>
        </MaterialRtl>
      </React.Suspense>
    </Router>
  );
};

const mapStateToProps = ({ user: { currentUser } }) => ({ currentUser });

export default connect(mapStateToProps)(Routes);
