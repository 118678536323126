import * as types from 'redux/actions/types';
import { deleteData, updateData } from '../utils';

const INITIAL_STATE = {
  data: [],
  count: 0
};

export const patternReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.searchPatternsSuccess:
    case types.getPatternsSuccess:
      const { data, count } = action.payload;
      return { ...state, data, count };
    case types.deletePatternSuccess:
      return deleteData(state, action.payload);
    case types.updatePatternSuccess:
      return updateData(state, action.payload);
    default:
      return state;
  }
};
