import provinces from './provinces';

export const dashboardTabOptions = [
  { label: '۱ روز', value: 1 },
  { label: '۱۰ روز', value: 10 },
  { label: '۳۰ روز', value: 30 },
  { label: '۳ ماه', value: 91 },
  { label: '۶ ماه', value: 182 },
  { label: '۱۲ ماه', value: 365 }
];

export const callReportsOptions = [
  { value: '7', label: 'هفت روز اخیر' },
  { value: '15', label: 'پانزده روز اخیر' },
  { value: '30', label: 'سی روز اخیر' }
];

export const layoutLessPages = ['/', '/login', '/404', '/unauthorized'];
// Default logo image
export const defaultLogo = `${process.env.PUBLIC_URL}/img/default-logo.png`;
// Active input options
export const activeOptions = [
  {
    label: 'فعال',
    value: 'true'
  },
  {
    label: 'غیر فعال',
    value: 'false'
  }
];
// Status input options
export const statusOptions = [
  {
    label: 'فعال',
    value: 'chosen'
  },
  {
    label: 'غیر فعال',
    value: 'ordinary'
  }
];

export const statusDetailOptions = [
  {
    label: 'همه فروشندگان',
    value: 'all'
  },
  {
    label: 'برگزیده',
    value: 'chosen'
  },
  {
    label: 'تولید‌کننده',
    value: 'producer'
  },
  {
    label: 'عادی',
    value: 'ordinary'
  }
];

export const creditOptions = [
  {
    label: 'کمتر از یک ماه',
    value: 'below30'
  },
  {
    label: 'یک تا سه ماه',
    value: '31to90'
  },
  {
    label: 'سه تا شش ماه',
    value: '91to180'
  }
];

export const dayOptions = Array.from(Array(31)).map((_, i) => {
  return {
    label: i + 1,
    value: i + 1
  };
});

const START_YEAR = 1320;
export const yearOptions = Array.from(Array(70)).map((_, i) => {
  return {
    label: i + START_YEAR,
    value: i + START_YEAR
  };
});

export const monthOptions = [
  {
    label: 'فروردین',
    value: '1'
  },
  {
    label: 'اردیبهشت',
    value: '2'
  },
  {
    label: 'خرداد',
    value: '3'
  },
  {
    label: 'تیر',
    value: '4'
  },
  {
    label: 'مرداد',
    value: '5'
  },
  {
    label: 'شهریور',
    value: '6'
  },
  {
    label: 'مهر',
    value: '7'
  },
  {
    label: 'آبان',
    value: '8'
  },
  {
    label: 'آذر',
    value: '9'
  },
  {
    label: 'دی',
    value: '10'
  },
  {
    label: 'بهمن',
    value: '11'
  },
  {
    label: 'اسفند',
    value: '12'
  }
];

export const provinceOptions = [
  ...new Set(provinces.map(({ name }) => name))
].map(p => ({ label: p, value: p }));

export const cityOptions = province =>
  provinces
    .filter(({ name }) => name === province)
    .map(({ city }) => ({ label: city, value: city }));

export const getCityCode = (province, city) =>
  provinces.find(p => p.name === province && p.city === city)?.code || '';

export const producerGeneralFields = [
  'name',
  'title',
  'factoryOwner',
  'ceo',
  'active',
  'phone',
  'website',
  'video',
  'description',
  'summary'
];

export const productPatternGeneralFields = [
  'name',
  'product',
  'producer',
  'active',
  'description'
];

export const specificationFields = [
  'weight',
  'thickness',
  'unit',
  'length',
  'dimensions',
  'category',
  'standard',
  'size',
  'channelWeight',
  'angleWeight',
  'state',
  'tier',
  'alloy',
  'subCategory',
  'grid',
  'innerDiagonal',
  'outerDiagonal',
  'symbol',
  'strengthRelation',
  'bendingDiagonal',
  'tensionalStrength',
  'highStrength',
  'signFeature',
  'widthDimension',
  'lengthDimension',
  'cutState'
];

export const addressFields = [
  'address',
  'province',
  'city',
  'postalCode',
  'location.map',
  'location.coordinates'
];

export const metaFields = ['metaTitle', 'metaDescription', 'metaUrl'];

export const socialMediasFields = [
  'linkedin',
  'facebook',
  'instagram',
  'telegram',
  'twitter'
];

export const PRICE_CHANGE_OPTIONS = [
  { value: 'INC', label: 'افزایش' },
  { value: 'DEC', label: 'کاهش' },
  { value: 'FIXED', label: 'ثابت' }
];
