import React from 'react';
import rtl from 'jss-rtl';
import { create } from 'jss';
import {
  createMuiTheme,
  StylesProvider,
  ThemeProvider,
  jssPreset
} from '@material-ui/core/styles';

const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  insertionPoint: document.getElementById('jss-insertion-point')
});

const MaterialRtl = ({ children }) => {
  const theme = createMuiTheme({
    direction: 'rtl',
    typography: {
      fontSize: 16,
      fontFamily: 'IRANSans, sans-serif, serif',
      lineHeight: 1.8,
      color: '#333',
      body1: {
        lineHeight: 1.8,
        textAlign: 'justify',
        fontSize: '1.5rem'
      }
    },
    palette: {
      primary: {
        light: '#21cbf3',
        main: '#2196f3',
        dark: '#126bb4',
        contrastText: '#fff'
      },
      secondary: {
        main: '#04c956',
        contrastText: '#fff'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 960,
        lg: 1500,
        xl: 1800
      }
    },
    overrides: {
      MuiButton: {
        root: {
          fontSize: '1.4rem',
          minWidth: '10rem'
        }
      },
      MuiDialog: {
        paper: {
          borderRadius: '1rem'
        }
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: 'currentColor'
        }
      },
      MuiAppBar: {
        colorPrimary: {
          color: '#333',
          backgroundColor: '#f7f7f7'
        }
      }
    }
  });
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StylesProvider>
  );
};

export default MaterialRtl;
