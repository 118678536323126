import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import MessageIcon from '@material-ui/icons/Message';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CategoryIcon from '@material-ui/icons/Category';
import StorageIcon from '@material-ui/icons/Storage';
import GestureIcon from '@material-ui/icons/Gesture';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import TocIcon from '@material-ui/icons/Toc';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import CreditCardIcon from '@material-ui/icons/CreditCard';
// import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';

import { lazyPreload } from 'utils/helpers';
// const Sellers = lazyPreload(() => import('pages/admin/sellers'));
// const NewSeller = lazyPreload(() => import('pages/admin/sellers/NewSeller'));
const ProducersPrice = lazyPreload(() => import('pages/admin/factoriesPrice'));
const FactoriesPage = lazyPreload(() => import('pages/admin/factories'));
const NewFactoryPage = lazyPreload(() =>
  import('pages/admin/factories/NewFactory')
);
const FactoryPage = lazyPreload(() => import('pages/admin/factories/Factory'));

const Products = lazyPreload(() =>
  import('pages/admin/productsManagement/products')
);
const ProductPatterns = lazyPreload(() =>
  import('pages/admin/productsManagement/patterns')
);
const NewProductPattern = lazyPreload(() =>
  import('pages/admin/productsManagement/patterns/NewPattern')
);
const EditProductPattern = lazyPreload(() =>
  import('pages/admin/productsManagement/patterns/EditPattern')
);
const UsersList = lazyPreload(() =>
  import('pages/admin/usersManagement/users')
);
const UsersReviews = lazyPreload(() =>
  import('pages/admin/usersManagement/reviews')
);
const UsersMessages = lazyPreload(() =>
  import('pages/admin/usersManagement/messages')
);
const PriceInquiries = lazyPreload(() =>
  import('pages/admin/usersManagement/inquiries')
);
const CreditSalesPage = lazyPreload(() =>
  import('pages/admin/usersManagement/credit-sales')
);
const UsersRoles = lazyPreload(() =>
  import('pages/admin/usersManagement/roles')
);
const News = lazyPreload(() => import('pages/admin/news'));
const CallReports = lazyPreload(() => import('pages/admin/calls'));
const Dashboard = lazyPreload(() => import('pages/admin/dashboard'));
const PriceCharts = lazyPreload(() => import('pages/admin/priceCharts'));
const ContentManagement = lazyPreload(() =>
  import('pages/admin/contentManagement')
);
const ProducerWeightContentManagement = lazyPreload(() =>
  import('pages/admin/contentManagement/ProducerWeight')
);

const adminRoutes = [
  // Dashboard
  {
    path: '/admin/dashboard',
    component: Dashboard,
    resource: 'dashboard',
    title: 'داشبورد',
    icon: DashboardIcon
  },
  // Products management
  {
    nested: true,
    title: 'مدیریت محصولات',
    icon: CategoryIcon,
    children: [
      {
        path: '/admin/products',
        component: Products,
        resource: 'products',
        title: 'محصولات',
        icon: StorageIcon
      },
      {
        path: '/admin/product-patterns',
        component: ProductPatterns,
        resource: 'patterns',
        title: 'الگو‌های محصول',
        icon: GestureIcon
      }
    ]
  },
  {
    path: '/admin/product-patterns/:id',
    component: EditProductPattern,
    resource: 'patterns'
  },
  {
    path: '/admin/add-product-pattern',
    component: NewProductPattern,
    resource: 'patterns'
  },
  {
    path: '/admin/call-reports',
    component: CallReports,
    resource: 'call-reports',
    title: 'گزارش تماس',
    icon: PhoneCallbackIcon
  },
  {
    path: '/admin/producers-price',
    component: ProducersPrice,
    resource: 'producers-price',
    title: 'قیمت تولید‌کنندگان',
    icon: MonetizationOnOutlinedIcon
  },
  {
    path: '/admin/factories',
    component: FactoriesPage,
    resource: 'producers',
    title: 'تولید‌کنندگان',
    icon: HomeWorkIcon
  },
  {
    path: '/admin/new-factory',
    component: NewFactoryPage,
    resource: 'producers'
  },
  {
    path: '/admin/factories/:id',
    component: FactoryPage,
    resource: 'producers'
  },
  // {
  //   path: '/admin/producers',
  //   component: Producers,
  //   resource: 'producers',
  //   title: 'تولید‌کنندگان',
  //   icon: HomeWorkIcon
  // },
  // {
  //   path: '/admin/add-producer',
  //   component: NewProducer,
  //   resource: 'producers'
  // },
  // {
  //   path: '/admin/producers/:id',
  //   component: EditProducer,
  //   resource: 'producers'
  // },
  {
    path: '/admin/news',
    component: News,
    resource: 'news',
    title: 'اخبار لحظه‌ایی',
    icon: AnnouncementIcon
  },
  {
    nested: true,
    title: 'مدیریت کاربران',
    icon: SupervisorAccountIcon,
    children: [
      {
        path: '/admin/users',
        component: UsersList,
        resource: 'users',
        title: 'کاربران',
        icon: PeopleOutlineIcon
      },
      {
        path: '/admin/users-reviews',
        component: UsersReviews,
        resource: 'reviews',
        title: 'نظرات کاربران',
        icon: MessageIcon,
        badge: true
      },
      {
        path: '/admin/users-messages',
        component: UsersMessages,
        resource: 'messages',
        title: 'پیام ها',
        icon: RecordVoiceOverIcon,
        badge: true
      },
      {
        path: '/admin/price-inquiries',
        component: PriceInquiries,
        resource: 'inquiries',
        title: 'استعلام قیمت‌ها',
        icon: EuroSymbolIcon,
        badge: true
      },
      {
        path: '/admin/credit-sales',
        component: CreditSalesPage,
        resource: 'creditSales',
        title: 'فروش اعتباری',
        icon: CreditCardIcon,
        badge: true
      },
      {
        path: '/admin/users-roles',
        component: UsersRoles,
        title: 'نقش‌ ها',
        icon: AccountTreeIcon
      }
    ]
  },
  {
    path: '/admin/content-management',
    component: ContentManagement,
    resource: 'content',
    title: 'مدیریت محتوا',
    icon: TocIcon
  },
  {
    path: '/admin/content-management/:referTo',
    component: ProducerWeightContentManagement,
    resource: 'content'
  },
  {
    path: '/admin/price-charts',
    component: PriceCharts,
    resource: 'price-charts',
    title: 'نمودار قیمت',
    icon: TrendingUpIcon
  }
  // {
  //   path: '/admin/sellers',
  //   component: Sellers,
  //   resource: 'sellers',
  //   title: 'فروشندگان',
  //   icon: GroupOutlinedIcon,
  //   key: 'k-5'
  // },
  // {
  //   path: '/admin/add-seller(\\?type=.*)?',
  //   component: NewSeller,
  //   resource: 'sellers',
  //   key: 'k-6'
  // },
];

const flatRoutes = adminRoutes.reduce(
  (acc, item) => (item.nested ? [...acc, ...item.children] : [...acc, item]),
  []
);

export const userPermissionsOptions = flatRoutes
  .filter(({ resource, title }) => resource && title)
  .map(({ resource, title }) => ({ value: resource, label: title }));

export const userPermissionsMap = flatRoutes
  .filter(({ resource, title }) => resource && title)
  .reduce((acc, { resource, title }) => ({ ...acc, [resource]: title }), {});

export const routes = flatRoutes.map(({ path, component, resource }) => ({
  path,
  component,
  resource
}));

export const sidebarList = adminRoutes.filter(
  ({ title }) => title !== undefined
);
