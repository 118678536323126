import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { analyticsReducer } from './analytic';
import { userReducer } from './user';
import { usersReducer } from './user/list';
import { newsReducer } from './news';
import { callReportsReducer } from './callReport';
import { categoryReducer } from './category';
import { contentsReducer } from './content';
import { factoriesReducer } from './factoriesPrice';
import { patternReducer } from './pattern';
import { patternsQueryReducer } from './pattern/queryReducer';
import { productReducer } from './product';
import { productsQueryReducer } from './product/queryReducer';
import { factoryReducer } from './producer';
import { paginateReducer } from './paginateParams';
import { loadingReducer } from './loading';
import { errorReducer } from './error';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'categories']
};

const rootReducer = combineReducers({
  analytics: analyticsReducer,
  user: userReducer,
  users: usersReducer,
  news: newsReducer,
  calls: callReportsReducer,
  categories: categoryReducer,
  contents: contentsReducer,
  factoriesPrices: factoriesReducer,
  patterns: patternReducer,
  patternsQuery: patternsQueryReducer,
  products: productReducer,
  productsQuery: productsQueryReducer,
  factories: factoryReducer,
  paginate: paginateReducer,
  loading: loadingReducer,
  error: errorReducer,
  toastr: toastrReducer,
  form: formReducer
});

export default persistReducer(persistConfig, rootReducer);
