import React from 'react';
import { useLocation } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PreloadLink from 'components/PreloadLink';
import { useStyles } from '../styles';

const SidebarListItem = ({ title, path, children }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <PreloadLink
      to={path}
      component={ListItem}
      button
      className={`${classes.item} ${pathname === path ? 'active' : ''}`}
    >
      <ListItemIcon className={classes.icon}>{children}</ListItemIcon>
      <ListItemText className={classes.text} primary={title} />
    </PreloadLink>
  );
};

export default SidebarListItem;
