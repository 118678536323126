import React, { useState, useEffect } from 'react';
import Badge from '@material-ui/core/Badge';

import socket from 'api/socket';
import NestedList from '../../NestedList';
import ListItem from '../../ListItem';

const _showBadge = (resource, state) => {
  switch (resource) {
    case 'messages':
    case 'reviews':
    case 'inquiries':
    case 'creditSales':
      return state[resource] !== 0;
    default:
      return false;
  }
};

const UserItems = ({ icon, items = [] }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    reviews: 0,
    messages: 0,
    inquiries: 0,
    creditSales: 0
  });

  useEffect(() => {
    socket.emit('mounted', null, data => {
      // console.log('message received', data);
      setState(data);
    });

    const increment = field => () => {
      setState(pState => ({ ...pState, [field]: pState[field] + 1 }));
    };

    const reduce = field => () => {
      setState(pState => ({
        ...pState,
        [field]: pState[field] > 0 ? pState[field] - 1 : 0
      }));
    };

    socket.on('newComment', increment('reviews'));
    socket.on('newMessage', increment('messages'));
    socket.on('newInquiry', increment('inquiries'));
    socket.on('newCreditSale', increment('creditSales'));

    socket.on('deleteComment', reduce('reviews'));
    socket.on('deleteMessage', reduce('messages'));
    socket.on('deleteInquiry', reduce('inquiries'));
    socket.on('deleteCreditSale', reduce('creditSales'));
    socket.on('updateMessage', reduce('messages'));
    socket.on('updateInquiry', reduce('inquiries'));
    socket.on('updateCreditSale', reduce('creditSales'));

    socket.on('updateComment', flag => {
      setState(pState => ({
        ...pState,
        reviews:
          flag === -1 && pState.reviews > 0
            ? pState.reviews - 1
            : pState.reviews + 1
      }));
    });
  }, []);

  return (
    <NestedList
      text="مدیریت کاربران"
      open={open}
      setOpen={setOpen}
      icon={icon}
      badgeContent={
        state.reviews + state.messages + state.inquiries + state.creditSales
      }
    >
      {items.map(({ path, title, icon: Icon, badge, resource }, i) => (
        <ListItem path={path} title={title} key={i}>
          <Badge
            variant="dot"
            color="primary"
            className="badge-anim"
            invisible={!badge || !_showBadge(resource, state)}
          >
            <Icon />
          </Badge>
        </ListItem>
      ))}
    </NestedList>
  );
};

export default UserItems;
