import { toastr } from 'react-redux-toastr';
import asreahanApi from 'api/asreahanApi';
import errorHandler from '../errorHandler';
import * as types from '../types';

export const getCallReports = (params = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: types.getCallReportsRequest });

      const { data } = await asreahanApi.get('/calls', {
        params: {
          select: '-updatedAt,-__v',
          sort: 'createdAt',
          ...params
        }
      });
      dispatch({
        type: types.getCallReportsSuccess,
        payload: { data: data.data.calls, count: data.totalDocs }
      });
    } catch (error) {
      errorHandler(error, dispatch, types.getCallReportsFailure);
    }
  };
};

export const addCallReport = (formData = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: types.addCallReportRequest });
      const { data } = await asreahanApi.post('/calls', formData);
      dispatch({
        type: types.addCallReportSuccess,
        payload: data.data.call
      });
      toastr.success('Success', 'گزارش جدید با موفقیت اضافه شد');
    } catch (error) {
      errorHandler(error, dispatch, types.addCallReportFailure);
    }
  };
};

export const updateCallReport = (id, fields = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: types.updateCallReportRequest });

      const { data } = await asreahanApi.patch(`/calls/${id}`, fields);
      dispatch({
        type: types.updateCallReportSuccess,
        payload: data.data.call
      });
    } catch (error) {
      errorHandler(error, dispatch, types.updateCallReportFailure);
    }
  };
};

export const deleteCallReport = id => {
  return async dispatch => {
    try {
      dispatch({ type: types.deleteCallReportRequest });
      await asreahanApi.delete(`/calls/${id}`);
      dispatch({
        type: types.deleteCallReportSuccess,
        payload: id
      });
    } catch (error) {
      errorHandler(error, dispatch, types.deleteCallReportFailure);
    }
  };
};
