import { toastr } from 'react-redux-toastr';
import asreahanApi from 'api/asreahanApi';
import errorHandler from '../errorHandler';
import * as types from '../types';
import { RESOURCES } from '../types/resources';
import {
  getAll,
  updateOne,
  deleteOne,
  createOne,
  getOne
} from '../baseActions';

export const uploadProducerPhotos = (id, files) => {
  return async dispatch => {
    try {
      dispatch({ type: types.uploadPhotosRequest });

      const formData = new FormData();
      if (files.logo) formData.append('logo', files.logo[0]);
      if (files.photo) formData.append('photo', files.photo[0]);
      if (files.alt) formData.append('alt', files.alt);
      if (files.categoryId) formData.append('categoryId', files.categoryId);

      await asreahanApi.patch(`/producers/${id}/photos`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch({
        type: types.uploadPhotosSuccess
      });
      toastr.success('Success', 'تصاویر با موفقیت ثبت شد');
    } catch (error) {
      errorHandler(error, dispatch, types.uploadPhotosFailure);
    }
  };
};

export const getFactories = getAll(RESOURCES.PRODUCERS);
export const getFactory = getOne(RESOURCES.PRODUCERS);
export const updateFactory = updateOne(RESOURCES.PRODUCERS);
export const deleteFactory = deleteOne(RESOURCES.PRODUCERS);
export const createFactory = createOne(RESOURCES.PRODUCERS);
