import * as types from 'redux/actions/types';

const INITIAL_STATE = {
  sessions: 0,
  calls: 0,
  done: 0,
  reports: []
};

export const analyticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.getAnalyticsReportSuccess:
      return { ...state, reports: action.payload };
    case types.getTotalAnalyticsSuccess:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
