import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({
  component: Component,
  user,
  adminRoute = false,
  resource,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        // If the user does not exist, redirect to login page
        if (!user)
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location
                }
              }}
            />
          );
        // Check if the user has access to admin routes
        if (adminRoute && user.role !== 'admin' && user.role !== 'staff')
          return (
            <Redirect
              to={{
                pathname: '/unauthorized',
                state: {
                  from: props.location
                }
              }}
            />
          );
        // Check if user's role is 'staff' & route is protected
        if (adminRoute && user.role === 'staff') {
          const userPermissions = user.permissions || [];
          if (!userPermissions.includes(resource))
            return (
              <Redirect
                to={{
                  pathname: '/unauthorized',
                  state: {
                    from: props.location
                  }
                }}
              />
            );
        }

        return <Component {...rest} {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
