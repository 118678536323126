import { makeStyles } from '@material-ui/core/styles';

const DRAWER_WIDTH = '27rem';

export default makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: 'var(--color-light)',
    color: 'var(--color-dark)',
    borderBottom: '3px solid var(--color-grey)',
    boxShadow: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),

    '@media (max-width:700px)': {
      position: 'absolute'
    },

    '& > div.MuiToolbar-gutters': {
      padding: '0 6rem',
      display: 'flex',
      alignItems: 'center'
    }
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH})`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2),
    '& svg': {
      fontSize: '3rem'
    }
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0
  },
  drawerPaper: {
    width: DRAWER_WIDTH
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    padding: '3rem 6rem',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${DRAWER_WIDTH}`,

    '@media (max-width: 1300px)': {
      padding: '3rem 4rem'
    },

    '@media (max-width: 700px)': {
      padding: '0'
    }
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    overflow: 'hidden'
  },
  title: {
    color: 'var(--color-font)',
    fontSize: '1.6rem',
    margin: '0 2rem'
  },
  drawerAvatar: {
    maxWidth: '80%',
    margin: '0 auto',
    height: 'auto',
    width: 'auto'
  }
}));
