import moment from 'moment-jalaali';

import { e2p } from './';

moment.loadPersian({ dialect: 'persian-modern' });

const _calcDaysPassed = (date1, date2) =>
  Math.round(Math.abs(date2 - date1) / 864e5); // 1000 * 60 * 60 * 24

class FormatDate {
  // Convert date to shamsi format (short version)
  static toShamsi(date, usePersian = true, calcPassedDays = true) {
    if (calcPassedDays) {
      const daysPassed = _calcDaysPassed(new Date(), new Date(date));
      if (daysPassed === 0) return 'امروز';
      if (daysPassed === 1) return 'دیروز';
    }

    const shamsiDate = moment(date).format('jD jMMMM');
    return usePersian ? e2p(shamsiDate) : shamsiDate;
  }

  // Convert date to numeric shamsi format (full version) => 13400/01/01
  static toShamsiFull(date, usePersian = true) {
    const shamsiDate = moment(date, 'YYYY-MM-DD HH:mm:ss').format(
      'jYYYY/jM/jD'
    );
    return usePersian ? e2p(shamsiDate) : shamsiDate;
  }

  // Convert date to shamsi format (full version) => ۱۴۰۰ ۷ تیر
  static toShamsiFullText(date, usePersian = true) {
    const shamsiDate = `${moment(date).format('jD jMMMM')} ${moment(
      date
    ).format('jYYYY')}`;
    return usePersian ? e2p(shamsiDate) : shamsiDate;
  }

  // Convert date to miladi format
  static toMiladi(date) {
    return moment(date, 'jYYYY/jM/jD').format('YYYY-MM-DD');
  }

  static getMiladiYear(date) {
    return +moment(date, 'jYYYY/jM/jD').format('YYYY-MM-DD').slice(0, 4);
  }

  static addDaysToDate(date, days) {
    return moment(date).add(days, 'day').format('YYYY-MM-DD');
  }

  static subDaysFromDate(date, days) {
    return moment(date).subtract(days, 'day').format('YYYY-MM-DD');
  }

  static getJTodayParts() {
    const m = moment();
    return { year: m.jYear(), month: m.jMonth() + 1, day: m.jDate() };
  }

  static getToday() {
    return moment().format('YYYY-MM-DD');
  }
}

export default FormatDate;
