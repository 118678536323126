import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  list: {
    paddingTop: '2rem'
  },
  item: {
    padding: '1rem 2.5rem',
    borderTopLeftRadius: '5rem',
    borderBottomLeftRadius: '5rem',

    '&.active': {
      backgroundColor: 'var(--color-grey-light)',

      '& $icon .MuiSvgIcon-root': {
        color: 'var(--color-primary)'
      }
    }
  },
  text: {
    '& > .MuiTypography-body1': {
      fontSize: '1.4rem'
    }
  },
  icon: {
    minWidth: '4rem',

    '& .MuiSvgIcon-root': {
      fontSize: '2.6rem'
    }
  },
  collapse: {
    backgroundColor: 'var(--color-lighter)',

    '& $item': {
      padding: '0.9rem 0.5rem 0.9rem 5rem'
    },

    '& $text': {
      '& > .MuiTypography-body1': {
        fontSize: '1.25rem',
        color: 'var(--color-font)'
      }
    },

    '& $icon': {
      minWidth: '3rem',

      '& .MuiSvgIcon-root': {
        fontSize: '1.8rem'
      }
    }
  }
}));
