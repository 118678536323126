import React from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { layoutLessPages } from 'utils/data';
import PreloadLink from 'components/PreloadLink';
import SidebarList from './SidebarList';
import useStyles from './styles/main';

const MainPanel = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();
  const [open, setOpen] = React.useState(!isMobile);
  const { pathname } = useLocation();
  // Check if pathname is in layoutLessPages (pages without main layout!)
  if (layoutLessPages.includes(pathname)) return props.children;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <Tooltip
            arrow
            title={<span className="tooltip-text">باز‌ کردن منو</span>}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>

          <Avatar
            alt="asreahan-logo"
            className="avatar-bg"
            src={`${process.env.PUBLIC_URL}/img/icons/appBar-logo.png`}
          />
          <Typography className={classes.title}>فروشگاه عصرآهن</Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <PreloadLink to="/">
            <Avatar
              className={classes.drawerAvatar}
              variant="square"
              alt="asreahan-logo"
              src={`${process.env.PUBLIC_URL}/img/asreahan.svg`}
            />
          </PreloadLink>

          <Tooltip arrow title={<span className="tooltip-text">بستن منو</span>}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
        </div>

        <SidebarList />
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
};

export default MainPanel;
