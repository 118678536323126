import socketIOClient from 'socket.io-client';

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BASE_DEV_URL
    : process.env.REACT_APP_BASE_PROD_URL;

const socket = socketIOClient(BASE_URL, { transports: ['websocket'] });

export default socket;
