const provinces = [
  { name: 'آذربایجان شرقی', city: 'آذرشهر', code: '26320000' },
  { name: 'آذربایجان شرقی', city: 'اسکو', code: '26330000' },
  { name: 'آذربایجان شرقی', city: 'اهر', code: '26340000' },
  { name: 'آذربایجان شرقی', city: 'بستان آباد', code: '26440000' },
  { name: 'آذربایجان شرقی', city: 'بناب', code: '26420000' },
  { name: 'آذربایجان شرقی', city: 'تبریز', code: '26310000' },
  { name: 'آذربایجان شرقی', city: 'جلفا', code: '26380000' },
  { name: 'آذربایجان شرقی', city: 'چاراویماق', code: '26510000' },
  { name: 'آذربایجان شرقی', city: 'خداآفرین', code: '26530000' },
  { name: 'آذربایجان شرقی', city: 'سراب', code: '26350000' },
  { name: 'آذربایجان شرقی', city: 'شبستر', code: '26450000' },
  { name: 'آذربایجان شرقی', city: 'عجب شیر', code: '26490000' },
  { name: 'آذربایجان شرقی', city: 'کلیبر', code: '26470000' },
  { name: 'آذربایجان شرقی', city: 'مراغه', code: '26360000' },
  { name: 'آذربایجان شرقی', city: 'مرند', code: '26370000' },
  { name: 'آذربایجان شرقی', city: 'ملکان', code: '26430000' },
  { name: 'آذربایجان شرقی', city: 'میانه', code: '26390000' },
  { name: 'آذربایجان شرقی', city: 'ورزقان', code: '26520000' },
  { name: 'آذربایجان شرقی', city: 'هریس', code: '26480000' },
  { name: 'آذربایجان شرقی', city: 'هشترود', code: '26410000' },
  { name: 'آذربایجان شرقی', city: 'هوراند', code: '26344000' },
  { name: 'آذربایجان شرقی', city: 'آبش احمد', code: '26473000' },
  { name: 'آذربایجان شرقی', city: 'آقکند', code: '26393001' },
  { name: 'آذربایجان شرقی', city: 'اچاچی', code: '26391024' },
  { name: 'آذربایجان شرقی', city: 'ایلخچی', code: '26332000' },
  { name: 'آذربایجان شرقی', city: 'باسمنج', code: '26311028' },
  { name: 'آذربایجان شرقی', city: 'بخشایش', code: '26481024' },
  { name: 'آذربایجان شرقی', city: 'بناب مرند', code: '26371021' },
  { name: 'آذربایجان شرقی', city: 'ترک', code: '26394023' },
  { name: 'آذربایجان شرقی', city: 'ترکمانچای', code: '26392000' },
  { name: 'آذربایجان شرقی', city: 'تسوج', code: '26452023' },
  { name: 'آذربایجان شرقی', city: 'تیکمه داش', code: '26442000' },
  { name: 'آذربایجان شرقی', city: 'تیمورلو', code: '26322023' },
  { name: 'آذربایجان شرقی', city: 'جوان قلعه', code: '26492021' },
  { name: 'آذربایجان شرقی', city: 'خامنه', code: '26451028' },
  { name: 'آذربایجان شرقی', city: 'خداجو(خراجو)', code: '26362021' },
  { name: 'آذربایجان شرقی', city: 'خمارلو', code: '26531021' },
  { name: 'آذربایجان شرقی', city: 'خواجه', code: '26482000' },
  { name: 'آذربایجان شرقی', city: 'خوشه مهر', code: '26421022' },
  { name: 'آذربایجان شرقی', city: 'داریان', code: '26451029' },
  { name: 'آذربایجان شرقی', city: 'دوزدوزان', code: '26352027' },
  { name: 'آذربایجان شرقی', city: 'زرنق', code: '26481023' },
  { name: 'آذربایجان شرقی', city: 'زنوز', code: '26371023' },
  { name: 'آذربایجان شرقی', city: 'سردرود', code: '26311029' },
  { name: 'آذربایجان شرقی', city: 'سهند', code: '26331024' },
  { name: 'آذربایجان شرقی', city: 'سیس', code: '26450000' },
  { name: 'آذربایجان شرقی', city: 'سیه رود', code: '26382000' },
  { name: 'آذربایجان شرقی', city: 'شربیان', code: '26352023' },
  { name: 'آذربایجان شرقی', city: 'شرفخانه', code: '26451025' },
  { name: 'آذربایجان شرقی', city: 'شندآباد', code: '26451026' },
  { name: 'آذربایجان شرقی', city: 'صوفیان', code: '26453000' },
  { name: 'آذربایجان شرقی', city: 'عاشقلو', code: '26533022' },
  { name: 'آذربایجان شرقی', city: 'کلوانق', code: '26352028' },
  { name: 'آذربایجان شرقی', city: 'کوزه کنان', code: '26451024' },
  { name: 'آذربایجان شرقی', city: 'ممقان', code: '26321025' },
  { name: 'آذربایجان شرقی', city: 'مهربان', code: '26352000' },
  { name: 'آذربایجان شرقی', city: 'وایقان', code: '26451027' },
  { name: 'آذربایجان شرقی', city: 'هادیشهر', code: '26381025' },
  { name: 'آذربایجان شرقی', city: 'یامچی', code: '26372000' },
  { name: 'آذربایجان غربی', city: 'ارومیه', code: '57310000' },
  { name: 'آذربایجان غربی', city: 'اشنویه', code: '57450000' },
  { name: 'آذربایجان غربی', city: 'بوکان', code: '57320000' },
  { name: 'آذربایجان غربی', city: 'پلدشت', code: '57470000' },
  { name: 'آذربایجان غربی', city: 'پیرانشهر', code: '57330000' },
  { name: 'آذربایجان غربی', city: 'چالدران', code: '57420000' },
  { name: 'آذربایجان غربی', city: 'چایپاره', code: '57460000' },
  { name: 'آذربایجان غربی', city: 'خوی', code: '57350000' },
  { name: 'آذربایجان غربی', city: 'سلماس', code: '57370000' },
  { name: 'آذربایجان غربی', city: 'شاهین دژ', code: '57380000' },
  { name: 'آذربایجان غربی', city: 'شوط', code: '57480000' },
  { name: 'آذربایجان غربی', city: 'ماکو', code: '57410000' },
  { name: 'آذربایجان غربی', city: 'میاندوآب', code: '57430000' },
  { name: 'آذربایجان غربی', city: 'نقده', code: '57440000' },
  { name: 'آذربایجان غربی', city: 'آواجیق', code: '57422021' },
  { name: 'آذربایجان غربی', city: 'ایواوغلی', code: '57354000' },
  { name: 'آذربایجان غربی', city: 'باروق', code: '57433000' },
  { name: 'آذربایجان غربی', city: 'بازرگان', code: '57411021' },
  { name: 'آذربایجان غربی', city: 'تازه شهر', code: '57371021' },
  { name: 'آذربایجان غربی', city: 'خلیفان', code: '57392000' },
  { name: 'آذربایجان غربی', city: 'دیزج دیز', code: '57351027' },
  { name: 'آذربایجان غربی', city: 'ربط', code: '57361028' },
  { name: 'آذربایجان غربی', city: 'سیلوانه', code: '57314000' },
  { name: 'آذربایجان غربی', city: 'سیمینه', code: '57322000' },
  { name: 'آذربایجان غربی', city: 'سیه چشمه', code: '57421021' },
  { name: 'آذربایجان غربی', city: 'فیرورق', code: '57351021' },
  { name: 'آذربایجان غربی', city: 'قره ضیاءالدین', code: '57461021' },
  { name: 'آذربایجان غربی', city: 'قطور', code: '57352000' },
  { name: 'آذربایجان غربی', city: 'قوشچی', code: '57313021' },
  { name: 'آذربایجان غربی', city: 'کشاورز', code: '57382000' },
  { name: 'آذربایجان غربی', city: 'لاجان', code: '57332000' },
  { name: 'آذربایجان غربی', city: 'محمدیار', code: '57442000' },
  { name: 'آذربایجان غربی', city: 'نازک علیا', code: '57472021' },
  { name: 'آذربایجان غربی', city: 'نالوس', code: '57452000' },
  { name: 'آذربایجان غربی', city: 'نلاس', code: '57362023' },
  { name: 'آذربایجان غربی', city: 'نوشین', code: '57312021' },
  { name: 'آذربایجان غربی', city: 'یولاگلدی', code: '57481022' },
  { name: 'آذربایجان غربی', city: 'تکاب', code: '57340000' },
  { name: 'آذربایجان غربی', city: 'سردشت', code: '57360000' },
  { name: 'آذربایجان غربی', city: 'مهاباد', code: '57390000' },
  { name: 'اردبیل', city: 'اردبیل', code: '91310000' },
  { name: 'اردبیل', city: 'اصلاندوز', code: '91352000' },
  { name: 'اردبیل', city: 'بیله سوار', code: '91340000' },
  { name: 'اردبیل', city: 'پارس آباد', code: '91350000' },
  { name: 'اردبیل', city: 'خلخال', code: '91360000' },
  { name: 'اردبیل', city: 'سرعین', code: '91410000' },
  { name: 'اردبیل', city: 'کوثر', code: '91370000' },
  { name: 'اردبیل', city: 'گرمی', code: '91380000' },
  { name: 'اردبیل', city: 'مشگین شهر', code: '91390000' },
  { name: 'اردبیل', city: 'نمین', code: '91330000' },
  { name: 'اردبیل', city: 'نیر', code: '91320000' },
  { name: 'اردبیل', city: 'اردیموسی', code: '91412021' },
  { name: 'اردبیل', city: 'ثمرین', code: '91314000' },
  { name: 'اردبیل', city: 'رضی', code: '91393021' },
  { name: 'اردبیل', city: 'زهرا', code: '91381022' },
  { name: 'اردبیل', city: 'عنبران', code: '91333000' },
  { name: 'اردبیل', city: 'قصابه', code: '91394000' },
  { name: 'اردبیل', city: 'کلور', code: '91363024' },
  { name: 'اردبیل', city: 'کوراییم', code: '91322000' },
  { name: 'اردبیل', city: 'لاهرود', code: '91392021' },
  { name: 'اردبیل', city: 'مرادلو', code: '91393023' },
  { name: 'اردبیل', city: 'هشتجین', code: '91362022' },
  { name: 'اردبیل', city: 'هیر', code: '91313000' },
  { name: 'اصفهان', city: 'آران وبیدگل', code: '21430000' },
  { name: 'اصفهان', city: 'اردستان', code: '21320000' },
  { name: 'اصفهان', city: 'اصفهان', code: '21310000' },
  { name: 'اصفهان', city: 'برخوار', code: '21510000' },
  { name: 'اصفهان', city: 'چادگان', code: '21530000' },
  { name: 'اصفهان', city: 'خمینی شهر', code: '21330000' },
  { name: 'اصفهان', city: 'خوانسار', code: '21340000' },
  { name: 'اصفهان', city: 'دهاقان', code: '21560000' },
  { name: 'اصفهان', city: 'سمیرم', code: '21350000' },
  { name: 'اصفهان', city: 'شهرضا', code: '21390000' },
  { name: 'اصفهان', city: 'فریدن', code: '21360000' },
  { name: 'اصفهان', city: 'فریدونشهر', code: '21370000' },
  { name: 'اصفهان', city: 'فلاورجان', code: '21380000' },
  { name: 'اصفهان', city: 'کاشان', code: '21420000' },
  { name: 'اصفهان', city: 'گلپایگان', code: '21440000' },
  { name: 'اصفهان', city: 'لنجان', code: '21450000' },
  { name: 'اصفهان', city: 'مبارکه', code: '21520000' },
  { name: 'اصفهان', city: 'نایین', code: '21460000' },
  { name: 'اصفهان', city: 'نطنز', code: '21490000' },
  { name: 'اصفهان', city: 'ابریشم', code: '21381023' },
  { name: 'اصفهان', city: 'اژیه', code: '21313022' },
  { name: 'اصفهان', city: 'افوس', code: '21362021' },
  { name: 'اصفهان', city: 'انارک', code: '21462000' },
  { name: 'اصفهان', city: 'بادرود', code: '21492000' },
  { name: 'اصفهان', city: 'باغ بهادران', code: '21452000' },
  { name: 'اصفهان', city: 'بافران', code: '21461021' },
  { name: 'اصفهان', city: 'برزک', code: '21424000' },
  { name: 'اصفهان', city: 'برف انبار', code: '21371021' },
  { name: 'اصفهان', city: 'بویین ومیاندشت', code: '21362000' },
  { name: 'اصفهان', city: 'بهاران شهر', code: '21382023' },
  { name: 'اصفهان', city: 'بهارستان', code: '11490000' },
  { name: 'اصفهان', city: 'بیده', code: '21352022' },
  { name: 'اصفهان', city: 'تودشک', code: '21314021' },
  { name: 'اصفهان', city: 'جندق', code: '21571021' },
  { name: 'اصفهان', city: 'جوزدان', code: '21471022' },
  { name: 'اصفهان', city: 'جوشقان قالی', code: '21423021' },
  { name: 'اصفهان', city: 'چرمهین', code: '21452021' },
  { name: 'اصفهان', city: 'چمگردان', code: '21451024' },
  { name: 'اصفهان', city: 'خورزوق', code: '21513024' },
  { name: 'اصفهان', city: 'دامنه', code: '21361022' },
  { name: 'اصفهان', city: 'درچه', code: '21331021' },
  { name: 'اصفهان', city: 'دهق', code: '21472021' },
  { name: 'اصفهان', city: 'دیزیچه', code: '21521021' },
  { name: 'اصفهان', city: 'رزوه', code: '21531023' },
  { name: 'اصفهان', city: 'رضوانشهر', code: '54410000' },
  { name: 'اصفهان', city: 'زازران', code: '21381026' },
  { name: 'اصفهان', city: 'زرین شهر', code: '21451021' },
  { name: 'اصفهان', city: 'زواره', code: '21322000' },
  { name: 'اصفهان', city: 'سجزی', code: '21314026' },
  { name: 'اصفهان', city: 'سده لنجان', code: '21451022' },
  { name: 'اصفهان', city: 'طالخونچه', code: '21521022' },
  { name: 'اصفهان', city: 'عسگران', code: '21482022' },
  { name: 'اصفهان', city: 'علویجه', code: '21472022' },
  { name: 'اصفهان', city: 'فرخی', code: '21571023' },
  { name: 'اصفهان', city: 'فولادشهر', code: '21451025' },
  { name: 'اصفهان', city: 'قمصر', code: '21423000' },
  { name: 'اصفهان', city: 'قهجاورستان', code: '21311029' },
  { name: 'اصفهان', city: 'قهدریجان', code: '21381022' },
  { name: 'اصفهان', city: 'کرکوند', code: '21521024' },
  { name: 'اصفهان', city: 'کمه', code: '21352023' },
  { name: 'اصفهان', city: 'کهریزسنگ', code: '21471026' },
  { name: 'اصفهان', city: 'گرگاب', code: '21551023' },
  { name: 'اصفهان', city: 'گلشن', code: '33511021' },
  { name: 'اصفهان', city: 'گوگد', code: '21441021' },
  { name: 'اصفهان', city: 'لای بید', code: '21552026' },
  { name: 'اصفهان', city: 'نوش آباد', code: '21421034' },
  { name: 'اصفهان', city: 'نیاسر', code: '21422000' },
  { name: 'اصفهان', city: 'ورنامخواست', code: '21451023' },
  { name: 'اصفهان', city: 'وزوان', code: '21552021' },
  { name: 'اصفهان', city: 'هرند', code: '21313021' },
  { name: 'اصفهان', city: 'بو یین و میاندشت', code: '21362000' },
  { name: 'اصفهان', city: 'نجف آباد', code: '21470000' },
  { name: 'اصفهان', city: 'تیران', code: '21480000' },
  { name: 'اصفهان', city: 'خور', code: '21570000' },
  { name: 'اصفهان', city: 'شاهین شهر', code: '21550000' },
  { name: 'اصفهان', city: 'کوهپایه', code: '21314000' },
  { name: 'اصفهان', city: 'میمه', code: '21552000' },
  { name: 'البرز', city: 'اشتهارد', code: '18350000' },
  { name: 'البرز', city: 'ساوجبلاغ', code: '18320000' },
  { name: 'البرز', city: 'طالقان', code: '18330000' },
  { name: 'البرز', city: 'کرج', code: '18310000' },
  { name: 'البرز', city: 'نظرآباد', code: '18340000' },
  { name: 'البرز', city: 'آسارا', code: '18312000' },
  { name: 'البرز', city: 'تنکمان', code: '18342000' },
  { name: 'البرز', city: 'کمال شهر', code: '18311024' },
  { name: 'البرز', city: 'گرمدره', code: '18311025' },
  { name: 'البرز', city: 'ماهدشت', code: '18311021' },
  { name: 'البرز', city: 'محمدشهر', code: '18311023' },
  { name: 'البرز', city: 'مشکین دشت', code: '18311022' },
  { name: 'البرز', city: 'هشتگرد', code: '18321021' },
  { name: 'البرز', city: 'پلنگ آباد', code: '18352000' },
  { name: 'البرز', city: 'چهارباغ', code: '18323000' },
  { name: 'البرز', city: 'شهرجدیدهشتگرد', code: '18321021' },
  { name: 'ایلام', city: 'آبدانان', code: '83340000' },
  { name: 'ایلام', city: 'ایلام', code: '83310000' },
  { name: 'ایلام', city: 'ایوان', code: '83320000' },
  { name: 'ایلام', city: 'بدره', code: '83332000' },
  { name: 'ایلام', city: 'دره شهر', code: '83330000' },
  { name: 'ایلام', city: 'دهلران', code: '83350000' },
  { name: 'ایلام', city: 'سیروان', code: '71333022' },
  { name: 'ایلام', city: 'ملکشاهی', code: '83380000' },
  { name: 'ایلام', city: 'هلیلان', code: '83362000' },
  { name: 'ایلام', city: 'پهله', code: '83352026' },
  { name: 'ایلام', city: 'چشمه شیرین', code: '83332024' },
  { name: 'ایلام', city: 'چوار', code: '83312000' },
  { name: 'ایلام', city: 'دلگشا', code: '83382022' },
  { name: 'ایلام', city: 'زرنه', code: '83322000' },
  { name: 'ایلام', city: 'سراب باغ', code: '83342000' },
  { name: 'ایلام', city: 'شباب', code: '83361021' },
  { name: 'ایلام', city: 'لومار', code: '83391021' },
  { name: 'ایلام', city: 'ماژین', code: '83331022' },
  { name: 'ایلام', city: 'مورموری', code: '83342021' },
  { name: 'ایلام', city: 'موسیان', code: '83353000' },
  { name: 'ایلام', city: 'مهران', code: '83370000' },
  { name: 'بوشهر', city: 'بوشهر', code: '95310000' },
  { name: 'بوشهر', city: 'تنگستان', code: '95320000' },
  { name: 'بوشهر', city: 'جم', code: '95390000' },
  { name: 'بوشهر', city: 'دشتستان', code: '95340000' },
  { name: 'بوشهر', city: 'دیر', code: '95330000' },
  { name: 'بوشهر', city: 'دیلم', code: '95380000' },
  { name: 'بوشهر', city: 'عسلویه', code: '95410000' },
  { name: 'بوشهر', city: 'گناوه', code: '95370000' },
  { name: 'بوشهر', city: 'آباد', code: '95321038' },
  { name: 'بوشهر', city: 'آبدان', code: '95331021' },
  { name: 'بوشهر', city: 'امام حسن', code: '95382021' },
  { name: 'بوشهر', city: 'انارستان', code: '95392021' },
  { name: 'بوشهر', city: 'اهرم', code: '95321021' },
  { name: 'بوشهر', city: 'بادوله', code: '95352022' },
  { name: 'بوشهر', city: 'برازجان', code: '95341021' },
  { name: 'بوشهر', city: 'بردخون', code: '95332000' },
  { name: 'بوشهر', city: 'بندردیر', code: '95330001' },
  { name: 'بوشهر', city: 'بندردیلم', code: '95380001' },
  { name: 'بوشهر', city: 'بندرریگ', code: '95372001' },
  { name: 'بوشهر', city: 'بندرکنگان', code: '95360001' },
  { name: 'بوشهر', city: 'بندرگناوه', code: '95370001' },
  { name: 'بوشهر', city: 'بنک', code: '95361025' },
  { name: 'بوشهر', city: 'بوشکان', code: '95345022' },
  { name: 'بوشهر', city: 'بهارستان', code: '11490000' },
  { name: 'بوشهر', city: 'بیدخون', code: '95361026' },
  { name: 'بوشهر', city: 'تنگ ارم', code: '95344000' },
  { name: 'بوشهر', city: 'چاه مبارک', code: '95412000' },
  { name: 'بوشهر', city: 'چغادک', code: '95311023' },
  { name: 'بوشهر', city: 'خارک', code: '95312000' },
  { name: 'بوشهر', city: 'خورموج', code: '95351021' },
  { name: 'بوشهر', city: 'دالکی', code: '95341025' },
  { name: 'بوشهر', city: 'دلوار', code: '95322000' },
  { name: 'بوشهر', city: 'دوراهک', code: '95331022' },
  { name: 'بوشهر', city: 'ریز', code: '95392000' },
  { name: 'بوشهر', city: 'شبانکاره', code: '95343000' },
  { name: 'بوشهر', city: 'شنبه', code: '95351024' },
  { name: 'بوشهر', city: 'کاکی', code: '95352000' },
  { name: 'بوشهر', city: 'کلمه', code: '95345021' },
  { name: 'بوشهر', city: 'نخل تقی', code: '95411021' },
  { name: 'بوشهر', city: 'وحدتیه', code: '95342023' },
  { name: 'بوشهر', city: 'دشتی', code: '95350000' },
  { name: 'بوشهر', city: 'کنگان', code: '95360000' },
  { name: 'بوشهر', city: 'سعد آباد', code: '95342000' },
  { name: 'تهران', city: 'اسلامشهر', code: '11330000' },
  { name: 'تهران', city: 'بهارستان', code: '11490000' },
  { name: 'تهران', city: 'پاکدشت', code: '11440000' },
  { name: 'تهران', city: 'پردیس', code: '11480000' },
  { name: 'تهران', city: 'پیشوا', code: '11520000' },
  { name: 'تهران', city: 'تهران', code: '11320000' },
  { name: 'تهران', city: 'دماوند', code: '11340000' },
  { name: 'تهران', city: 'رباط کریم', code: '11410000' },
  { name: 'تهران', city: 'ری', code: '11360000' },
  { name: 'تهران', city: 'شمیرانات', code: '11380000' },
  { name: 'تهران', city: 'شهریار', code: '11390000' },
  { name: 'تهران', city: 'فیروزکوه', code: '11350000' },
  { name: 'تهران', city: 'قدس', code: '11460000' },
  { name: 'تهران', city: 'قرچک', code: '11510000' },
  { name: 'تهران', city: 'ملارد', code: '11470000' },
  { name: 'تهران', city: 'ورامین', code: '11430000' },
  { name: 'تهران', city: 'آبسرد', code: '11341024' },
  { name: 'تهران', city: 'آبعلی', code: '11342021' },
  { name: 'تهران', city: 'ارجمند', code: '11352000' },
  { name: 'تهران', city: 'باقرشهر', code: '11363021' },
  { name: 'تهران', city: 'بومهن', code: '11481021' },
  { name: 'تهران', city: 'پرند', code: '11411025' },
  { name: 'تهران', city: 'تجریش', code: '11381021' },
  { name: 'تهران', city: 'رودهن', code: '11342000' },
  { name: 'تهران', city: 'شاهدشهر', code: '11391031' },
  { name: 'تهران', city: 'شمشک', code: '11382022' },
  { name: 'تهران', city: 'صالحیه', code: '33513025' },
  { name: 'تهران', city: 'صباشهر', code: '11391023' },
  { name: 'تهران', city: 'فشم', code: '11382021' },
  { name: 'تهران', city: 'قلعه نو', code: '11363022' },
  { name: 'تهران', city: 'کهریزک', code: '11363000' },
  { name: 'تهران', city: 'کیلان', code: '11341021' },
  { name: 'تهران', city: 'نسیم شهر', code: '11491021' },
  { name: 'تهران', city: 'وحیدیه', code: '11391022' },
  { name: 'تهران', city: 'جوادآباد', code: '11432000' },
  { name: 'تهران', city: 'چهاردانگه', code: '11332000' },
  { name: 'تهران', city: 'رباطکریم', code: '11410000' },
  { name: 'تهران', city: 'شریف آباد', code: '11442000' },
  { name: 'تهران', city: 'صفادشت', code: '11472000' },
  { name: 'تهران', city: 'گلستان', code: '11492000' },
  { name: 'تهران', city: 'لواسان', code: '11383000' },
  { name: 'چهارمحال وبختیاری', city: 'اردل', code: '77320000' },
  { name: 'چهارمحال وبختیاری', city: 'بروجن', code: '77330000' },
  { name: 'چهارمحال وبختیاری', city: 'خانمیرزا', code: '77352000' },
  { name: 'چهارمحال وبختیاری', city: 'سامان', code: '77380000' },
  { name: 'چهارمحال وبختیاری', city: 'شهرکرد', code: '77310000' },
  { name: 'چهارمحال وبختیاری', city: 'فارسان', code: '77340000' },
  { name: 'چهارمحال وبختیاری', city: 'کوهرنگ', code: '77360000' },
  { name: 'چهارمحال وبختیاری', city: 'کیار', code: '77370000' },
  { name: 'چهارمحال وبختیاری', city: 'لردگان', code: '77350000' },
  { name: 'چهارمحال وبختیاری', city: 'آلونی', code: '77352021' },
  { name: 'چهارمحال وبختیاری', city: 'باباحیدر', code: '77341022' },
  { name: 'چهارمحال وبختیاری', city: 'بازفت', code: '77362000' },
  { name: 'چهارمحال وبختیاری', city: 'بلداجی', code: '77333000' },
  { name: 'چهارمحال وبختیاری', city: 'جونقان', code: '77341021' },
  { name: 'چهارمحال وبختیاری', city: 'چلگرد', code: '77361021' },
  { name: 'چهارمحال وبختیاری', city: 'چلیچه', code: '77341025' },
  { name: 'چهارمحال وبختیاری', city: 'دشتک', code: '32331044' },
  { name: 'چهارمحال وبختیاری', city: 'سرخون', code: '77351031' },
  { name: 'چهارمحال وبختیاری', city: 'سودجان', code: '77312025' },
  { name: 'چهارمحال وبختیاری', city: 'سورشجان', code: '77312021' },
  { name: 'چهارمحال وبختیاری', city: 'صمصامی', code: '77362022' },
  { name: 'چهارمحال وبختیاری', city: 'طاقانک', code: '77311024' },
  { name: 'چهارمحال وبختیاری', city: 'کیان', code: '77311022' },
  { name: 'چهارمحال وبختیاری', city: 'گندمان', code: '77332000' },
  { name: 'چهارمحال وبختیاری', city: 'گوجان', code: '77341028' },
  { name: 'چهارمحال وبختیاری', city: 'گهرو', code: '77371023' },
  { name: 'چهارمحال وبختیاری', city: 'مال خلیفه', code: '77353022' },
  { name: 'چهارمحال وبختیاری', city: 'منج', code: '77354000' },
  { name: 'چهارمحال وبختیاری', city: 'ناغان', code: '77372000' },
  { name: 'چهارمحال وبختیاری', city: 'نقنه', code: '77331024' },
  { name: 'چهارمحال وبختیاری', city: 'وردنجان', code: '77391021' },
  { name: 'چهارمحال وبختیاری', city: 'هارونی', code: '77312022' },
  { name: 'چهارمحال وبختیاری', city: 'هفشجان', code: '77311023' },
  { name: 'چهارمحال وبختیاری', city: 'هوره', code: '77382000' },
  { name: 'چهارمحال وبختیاری', city: 'یان چشمه', code: '77392000' },
  { name: 'چهارمحال وبختیاری', city: 'بن', code: '77390000' },
  { name: 'خراسان جنوبی', city: 'بشرویه', code: '33490000' },
  { name: 'خراسان جنوبی', city: 'بیرجند', code: '33310000' },
  { name: 'خراسان جنوبی', city: 'خوسف', code: '33520000' },
  { name: 'خراسان جنوبی', city: 'درمیان', code: '33480000' },
  { name: 'خراسان جنوبی', city: 'سرایان', code: '33460000' },
  { name: 'خراسان جنوبی', city: 'سربیشه', code: '33330000' },
  { name: 'خراسان جنوبی', city: 'طبس', code: '33510000' },
  { name: 'خراسان جنوبی', city: 'نهبندان', code: '33320000' },
  { name: 'خراسان جنوبی', city: 'آبیز', code: '33341037' },
  { name: 'خراسان جنوبی', city: 'آیسک', code: '33461021' },
  { name: 'خراسان جنوبی', city: 'ارسک', code: '33492000' },
  { name: 'خراسان جنوبی', city: 'خضری دشت بیاض', code: '33342021' },
  { name: 'خراسان جنوبی', city: 'درح', code: '33331021' },
  { name: 'خراسان جنوبی', city: 'دیهوک', code: '33512000' },
  { name: 'خراسان جنوبی', city: 'زهان', code: '33344000' },
  { name: 'خراسان جنوبی', city: 'سه قلعه', code: '33462000' },
  { name: 'خراسان جنوبی', city: 'شوسف', code: '33322000' },
  { name: 'خراسان جنوبی', city: 'قهستان', code: '33483000' },
  { name: 'خراسان جنوبی', city: 'گزیک', code: '33482000' },
  { name: 'خراسان جنوبی', city: 'محمدشهر', code: '18311023' },
  { name: 'خراسان جنوبی', city: 'مود', code: '33332000' },
  { name: 'خراسان جنوبی', city: 'نیمبلوک', code: '33342000' },
  { name: 'خراسان جنوبی', city: 'زیرکوه', code: '33343000' },
  { name: 'خراسان جنوبی', city: 'فردوس', code: '33470000' },
  { name: 'خراسان جنوبی', city: 'قاین', code: '33340001' },
  { name: 'خراسان رضوی', city: 'بجستان', code: '31660000' },
  { name: 'خراسان رضوی', city: 'بینالود', code: '31670000' },
  { name: 'خراسان رضوی', city: 'تایباد', code: '31360000' },
  { name: 'خراسان رضوی', city: 'تربت جام', code: '31370000' },
  { name: 'خراسان رضوی', city: 'تربت حیدریه', code: '31380000' },
  { name: 'خراسان رضوی', city: 'جغتای', code: '31640000' },
  { name: 'خراسان رضوی', city: 'جوین', code: '31650000' },
  { name: 'خراسان رضوی', city: 'چناران', code: '31390000' },
  { name: 'خراسان رضوی', city: 'خواف', code: '31410000' },
  { name: 'خراسان رضوی', city: 'داورزن', code: '31730000' },
  { name: 'خراسان رضوی', city: 'درگز', code: '31420000' },
  { name: 'خراسان رضوی', city: 'رشتخوار', code: '31590000' },
  { name: 'خراسان رضوی', city: 'زاوه', code: '31690000' },
  { name: 'خراسان رضوی', city: 'سبزوار', code: '31430000' },
  { name: 'خراسان رضوی', city: 'سرخس', code: '31440000' },
  { name: 'خراسان رضوی', city: 'فریمان', code: '31480000' },
  { name: 'خراسان رضوی', city: 'فیروزه', code: '31680000' },
  { name: 'خراسان رضوی', city: 'قوچان', code: '31520000' },
  { name: 'خراسان رضوی', city: 'کاشمر', code: '31540000' },
  { name: 'خراسان رضوی', city: 'کوهسرخ', code: '31543000' },
  { name: 'خراسان رضوی', city: 'گناباد', code: '31550000' },
  { name: 'خراسان رضوی', city: 'مه ولات', code: '31610000' },
  { name: 'خراسان رضوی', city: 'نیشابور', code: '31570000' },
  { name: 'خراسان رضوی', city: 'انابد', code: '31532000' },
  { name: 'خراسان رضوی', city: 'باجگیران', code: '31522000' },
  { name: 'خراسان رضوی', city: 'بایک', code: '31382000' },
  { name: 'خراسان رضوی', city: 'بیدخت', code: '31551021' },
  { name: 'خراسان رضوی', city: 'چاپشلو', code: '31422000' },
  { name: 'خراسان رضوی', city: 'چخماق', code: '31691023' },
  { name: 'خراسان رضوی', city: 'رباط سنگ', code: '31387023' },
  { name: 'خراسان رضوی', city: 'رضویه', code: '31313000' },
  { name: 'خراسان رضوی', city: 'ریواده', code: '31642025' },
  { name: 'خراسان رضوی', city: 'ریوش', code: '31543025' },
  { name: 'خراسان رضوی', city: 'سلامی', code: '31414000' },
  { name: 'خراسان رضوی', city: 'سمیع آباد', code: '31371027' },
  { name: 'خراسان رضوی', city: 'شادمهر', code: '31612000' },
  { name: 'خراسان رضوی', city: 'شاندیز', code: '31671021' },
  { name: 'خراسان رضوی', city: 'ششتمد', code: '31436000' },
  { name: 'خراسان رضوی', city: 'طرقبه', code: '31672000' },
  { name: 'خراسان رضوی', city: 'قلندرآباد', code: '31482000' },
  { name: 'خراسان رضوی', city: 'کاخک', code: '31552000' },
  { name: 'خراسان رضوی', city: 'کدکن', code: '31386000' },
  { name: 'خراسان رضوی', city: 'گلبهار', code: '31392000' },
  { name: 'خراسان رضوی', city: 'گلمکان', code: '31392025' },
  { name: 'خراسان رضوی', city: 'مزدآوند', code: '31442021' },
  { name: 'خراسان رضوی', city: 'مشهدریزه', code: '31363021' },
  { name: 'خراسان رضوی', city: 'ملک آباد', code: '61313029' },
  { name: 'خراسان رضوی', city: 'نشتیفان', code: '31411023' },
  { name: 'خراسان رضوی', city: 'نقاب', code: '31651021' },
  { name: 'خراسان رضوی', city: 'نوخندان', code: '31424000' },
  { name: 'خراسان رضوی', city: 'یونسی', code: '31662000' },
  { name: 'خراسان رضوی', city: 'خلیل آباد', code: '31620000' },
  { name: 'خراسان رضوی', city: 'صالح آباد', code: '31373000' },
  { name: 'خراسان رضوی', city: 'کلات', code: '31630000' },
  { name: 'خراسان رضوی', city: 'مشهد', code: '31310000' },
  { name: 'خراسان رضوی', city: 'جنگل', code: '31592000' },
  { name: 'خراسان رضوی', city: 'سنگان', code: '31412000' },
  { name: 'خراسان رضوی', city: 'نصرآباد', code: '31372000' },
  { name: 'خراسان شمالی', city: 'اسفراین', code: '32340000' },
  { name: 'خراسان شمالی', city: 'بجنورد', code: '32310000' },
  { name: 'خراسان شمالی', city: 'جاجرم', code: '32320000' },
  { name: 'خراسان شمالی', city: 'فاروج', code: '32360000' },
  { name: 'خراسان شمالی', city: 'گرمه', code: '32370000' },
  { name: 'خراسان شمالی', city: 'مانه وسملقان', code: '32330000' },
  { name: 'خراسان شمالی', city: 'آشخانه', code: '32331021' },
  { name: 'خراسان شمالی', city: 'پیش قلعه', code: '32333000' },
  { name: 'خراسان شمالی', city: 'تیتکانلو', code: '32362021' },
  { name: 'خراسان شمالی', city: 'حصارگرمخان', code: '32313022' },
  { name: 'خراسان شمالی', city: 'درق', code: '32371021' },
  { name: 'خراسان شمالی', city: 'راز', code: '32312000' },
  { name: 'خراسان شمالی', city: 'سنخواست', code: '32322000' },
  { name: 'خراسان شمالی', city: 'شوقان', code: '32323000' },
  { name: 'خراسان شمالی', city: 'صفی آباد', code: '32342021' },
  { name: 'خراسان شمالی', city: 'قاضی', code: '32332021' },
  { name: 'خراسان شمالی', city: 'قوشخانه', code: '32352023' },
  { name: 'خراسان شمالی', city: 'شیروان', code: '32350000' },
  { name: 'خوزستان', city: 'آبادان', code: '36320000' },
  { name: 'خوزستان', city: 'آغاجاری', code: '36590000' },
  { name: 'خوزستان', city: 'اندیکا', code: '36550000' },
  { name: 'خوزستان', city: 'اندیمشک', code: '36330000' },
  { name: 'خوزستان', city: 'اهواز', code: '36310000' },
  { name: 'خوزستان', city: 'ایذه', code: '36340000' },
  { name: 'خوزستان', city: 'باغ ملک', code: '36350000' },
  { name: 'خوزستان', city: 'باوی', code: '36560000' },
  { name: 'خوزستان', city: 'بندرماهشهر', code: '36360001' },
  { name: 'خوزستان', city: 'بهبهان', code: '36370000' },
  { name: 'خوزستان', city: 'حمیدیه', code: '36580000' },
  { name: 'خوزستان', city: 'خرمشهر', code: '36380000' },
  { name: 'خوزستان', city: 'دزفول', code: '36390000' },
  { name: 'خوزستان', city: 'دشت آزادگان', code: '36430000' },
  { name: 'خوزستان', city: 'رامشیر', code: '36490000' },
  { name: 'خوزستان', city: 'رامهرمز', code: '36440000' },
  { name: 'خوزستان', city: 'شوش', code: '36420000' },
  { name: 'خوزستان', city: 'شوشتر', code: '36450000' },
  { name: 'خوزستان', city: 'کارون', code: '36570000' },
  { name: 'خوزستان', city: 'گتوند', code: '36510000' },
  { name: 'خوزستان', city: 'لالی', code: '36520000' },
  { name: 'خوزستان', city: 'مسجدسلیمان', code: '36460000' },
  { name: 'خوزستان', city: 'هندیجان', code: '36480000' },
  { name: 'خوزستان', city: 'هویزه', code: '36530000' },
  { name: 'خوزستان', city: 'اروندکنار', code: '36322000' },
  { name: 'خوزستان', city: 'الوان', code: '36422022' },
  { name: 'خوزستان', city: 'الهایی', code: '36311030' },
  { name: 'خوزستان', city: 'بندرامام خمینی', code: '36362000' },
  { name: 'خوزستان', city: 'بیدروبه', code: '36332025' },
  { name: 'خوزستان', city: 'تشان', code: '36371024' },
  { name: 'خوزستان', city: 'جایزان', code: '36472000' },
  { name: 'خوزستان', city: 'جنت مکان', code: '36511021' },
  { name: 'خوزستان', city: 'جولکی', code: '36592000' },
  { name: 'خوزستان', city: 'چغامیش', code: '36393000' },
  { name: 'خوزستان', city: 'چم گلک', code: '36391025' },
  { name: 'خوزستان', city: 'چمران', code: '36361021' },
  { name: 'خوزستان', city: 'حسینیه', code: '36332021' },
  { name: 'خوزستان', city: 'خنافره', code: '36411027' },
  { name: 'خوزستان', city: 'دارخوین', code: '36411028' },
  { name: 'خوزستان', city: 'دهدز', code: '36342000' },
  { name: 'خوزستان', city: 'رفیع', code: '36532021' },
  { name: 'خوزستان', city: 'زاووت', code: '36552021' },
  { name: 'خوزستان', city: 'زهره', code: '36482021' },
  { name: 'خوزستان', city: 'سرداران', code: '75361028' },
  { name: 'خوزستان', city: 'سماله', code: '36512021' },
  { name: 'خوزستان', city: 'سوسنگرد', code: '36431021' },
  { name: 'خوزستان', city: 'شاوور', code: '36422000' },
  { name: 'خوزستان', city: 'صفی آباد', code: '32342021' },
  { name: 'خوزستان', city: 'صیدون', code: '36352000' },
  { name: 'خوزستان', city: 'عرب حسن', code: '36451027' },
  { name: 'خوزستان', city: 'قلعه تل', code: '36351023' },
  { name: 'خوزستان', city: 'قلعه خواجه', code: '36551021' },
  { name: 'خوزستان', city: 'گوریه', code: '36451023' },
  { name: 'خوزستان', city: 'مشراگه', code: '36492000' },
  { name: 'خوزستان', city: 'مقاومت', code: '36381021' },
  { name: 'خوزستان', city: 'ملاثانی', code: '36561021' },
  { name: 'خوزستان', city: 'میداود', code: '36351021' },
  { name: 'خوزستان', city: 'مینوشهر', code: '36382001' },
  { name: 'خوزستان', city: 'ویس', code: '36562000' },
  { name: 'خوزستان', city: 'هفتگل', code: '36540000' },
  { name: 'خوزستان', city: 'امیدیه', code: '36470000' },
  { name: 'خوزستان', city: 'شادگان', code: '36410000' },
  { name: 'خوزستان', city: 'هفتکل', code: '36540000' },
  { name: 'خوزستان', city: 'آبژدان', code: '36553000' },
  { name: 'خوزستان', city: 'بستان', code: '36432000' },
  { name: 'زنجان', city: 'ابهر', code: '67350000' },
  { name: 'زنجان', city: 'ایجرود', code: '67320000' },
  { name: 'زنجان', city: 'خرمدره', code: '67360000' },
  { name: 'زنجان', city: 'زنجان', code: '67310000' },
  { name: 'زنجان', city: 'سلطانیه', code: '67352000' },
  { name: 'زنجان', city: 'آب بر', code: '67331021' },
  { name: 'زنجان', city: 'ارمغانخانه', code: '67311069' },
  { name: 'زنجان', city: 'چورزق', code: '67332000' },
  { name: 'زنجان', city: 'حلب', code: '67322000' },
  { name: 'زنجان', city: 'دندی', code: '67342021' },
  { name: 'زنجان', city: 'سجاس', code: '67372000' },
  { name: 'زنجان', city: 'سهرورد', code: '67371023' },
  { name: 'زنجان', city: 'صایین قلعه', code: '67351026' },
  { name: 'زنجان', city: 'کرسف', code: '67371021' },
  { name: 'زنجان', city: 'ماه نشان', code: '67340000' },
  { name: 'زنجان', city: 'نیک پی', code: '67312022' },
  { name: 'زنجان', city: 'هیدج', code: '67351028' },
  { name: 'زنجان', city: 'طارم', code: '67330000' },
  { name: 'زنجان', city: 'ماهنشان', code: '67340000' },
  { name: 'سمنان', city: 'آرادان', code: '87380000' },
  { name: 'سمنان', city: 'دامغان', code: '87320000' },
  { name: 'سمنان', city: 'سمنان', code: '87310000' },
  { name: 'سمنان', city: 'گرمسار', code: '87340000' },
  { name: 'سمنان', city: 'مهدی شهر', code: '87350000' },
  { name: 'سمنان', city: 'ایوانکی', code: '87343000' },
  { name: 'سمنان', city: 'بیارجمند', code: '87333000' },
  { name: 'سمنان', city: 'شهمیرزاد', code: '87352000' },
  { name: 'سمنان', city: 'کهن آباد', code: '87382000' },
  { name: 'سمنان', city: 'مجن', code: '87331024' },
  { name: 'سمنان', city: 'سرخه', code: '87360000' },
  { name: 'سمنان', city: 'شاهرود', code: '87330000' },
  { name: 'سمنان', city: 'میامی', code: '87370000' },
  { name: 'سیستان وبلوچستان', city: 'ایرانشهر', code: '61320000' },
  { name: 'سیستان وبلوچستان', city: 'بمپور', code: '61323000' },
  { name: 'سیستان وبلوچستان', city: 'تفتان', code: '61342024' },
  { name: 'سیستان وبلوچستان', city: 'خاش', code: '61340000' },
  { name: 'سیستان وبلوچستان', city: 'دشتیاری', code: '61333000' },
  { name: 'سیستان وبلوچستان', city: 'دلگان', code: '61420000' },
  { name: 'سیستان وبلوچستان', city: 'راسک', code: '61383000' },
  { name: 'سیستان وبلوچستان', city: 'زابل', code: '61350000' },
  { name: 'سیستان وبلوچستان', city: 'زاهدان', code: '61310000' },
  { name: 'سیستان وبلوچستان', city: 'زهک', code: '61410000' },
  { name: 'سیستان وبلوچستان', city: 'سرباز', code: '61380000' },
  { name: 'سیستان وبلوچستان', city: 'سیب و سوران', code: '61440000' },
  { name: 'سیستان وبلوچستان', city: 'فنوج', code: '61373000' },
  { name: 'سیستان وبلوچستان', city: 'قصرقند', code: '61490000' },
  { name: 'سیستان وبلوچستان', city: 'کنارک', code: '61390000' },
  { name: 'سیستان وبلوچستان', city: 'میرجاوه', code: '61480000' },
  { name: 'سیستان وبلوچستان', city: 'نیک شهر', code: '61370000' },
  { name: 'سیستان وبلوچستان', city: 'نیمروز', code: '61470000' },
  { name: 'سیستان وبلوچستان', city: 'هامون', code: '61460000' },
  { name: 'سیستان وبلوچستان', city: 'هیرمند', code: '61430000' },
  { name: 'سیستان وبلوچستان', city: 'آشار', code: '61452000' },
  { name: 'سیستان وبلوچستان', city: 'ادیمی', code: '61471022' },
  { name: 'سیستان وبلوچستان', city: 'اسپکه', code: '61375021' },
  { name: 'سیستان وبلوچستان', city: 'بزمان', code: '61322000' },
  { name: 'سیستان وبلوچستان', city: 'بنت', code: '61372000' },
  { name: 'سیستان وبلوچستان', city: 'بنجار', code: '61351021' },
  { name: 'سیستان وبلوچستان', city: 'پارود', code: '61381023' },
  { name: 'سیستان وبلوچستان', city: 'پلان', code: '61334000' },
  { name: 'سیستان وبلوچستان', city: 'پیشین', code: '61382000' },
  { name: 'سیستان وبلوچستان', city: 'جالق', code: '61363000' },
  { name: 'سیستان وبلوچستان', city: 'جزینک', code: '61412000' },
  { name: 'سیستان وبلوچستان', city: 'چگرد', code: '61422021' },
  { name: 'سیستان وبلوچستان', city: 'ریگ ملک', code: '61481025' },
  { name: 'سیستان وبلوچستان', city: 'ساربوک', code: '61491022' },
  { name: 'سیستان وبلوچستان', city: 'سرجنگل', code: '61313023' },
  { name: 'سیستان وبلوچستان', city: 'سیرکان', code: '61362023' },
  { name: 'سیستان وبلوچستان', city: 'قرقری', code: '61432000' },
  { name: 'سیستان وبلوچستان', city: 'گتیج', code: '61373022' },
  { name: 'سیستان وبلوچستان', city: 'نگور', code: '61333021' },
  { name: 'سیستان وبلوچستان', city: 'نوک آباد', code: '61342000' },
  { name: 'سیستان وبلوچستان', city: 'چاه بهار', code: '61330000' },
  { name: 'سیستان وبلوچستان', city: 'سراوان', code: '61360000' },
  { name: 'سیستان وبلوچستان', city: 'هیدوچ', code: '61442000' },
  { name: 'فارس', city: 'آباده', code: '41320000' },
  { name: 'فارس', city: 'ارسنجان', code: '41420000' },
  { name: 'فارس', city: 'استهبان', code: '41350000' },
  { name: 'فارس', city: 'اقلید', code: '41360000' },
  { name: 'فارس', city: 'اوز', code: '41593000' },
  { name: 'فارس', city: 'بختگان', code: '41453021' },
  { name: 'فارس', city: 'بوانات', code: '41340000' },
  { name: 'فارس', city: 'بیضا', code: '41392000' },
  { name: 'فارس', city: 'پاسارگاد', code: '41550000' },
  { name: 'فارس', city: 'جهرم', code: '41370000' },
  { name: 'فارس', city: 'خرامه', code: '41316021' },
  { name: 'فارس', city: 'خرم بید', code: '41330000' },
  { name: 'فارس', city: 'خفر', code: '41372000' },
  { name: 'فارس', city: 'خنج', code: '41540000' },
  { name: 'فارس', city: 'رستم', code: '41580000' },
  { name: 'فارس', city: 'زرقان', code: '41313000' },
  { name: 'فارس', city: 'سپیدان', code: '41390000' },
  { name: 'فارس', city: 'سرچهان', code: '41342000' },
  { name: 'فارس', city: 'سروستان', code: '41560000' },
  { name: 'فارس', city: 'فراشبند', code: '41520000' },
  { name: 'فارس', city: 'فسا', code: '41410000' },
  { name: 'فارس', city: 'قیروکارزین', code: '41530000' },
  { name: 'فارس', city: 'کازرون', code: '41440000' },
  { name: 'فارس', city: 'کوار', code: '41610000' },
  { name: 'فارس', city: 'گراش', code: '41590000' },
  { name: 'فارس', city: 'لامرد', code: '41470000' },
  { name: 'فارس', city: 'مرودشت', code: '41480000' },
  { name: 'فارس', city: 'ممسنی', code: '41490000' },
  { name: 'فارس', city: 'نی ریز', code: '41450000' },
  { name: 'فارس', city: 'آباده طشک', code: '41453000' },
  { name: 'فارس', city: 'اردکان', code: '41391021' },
  { name: 'فارس', city: 'اسیر', code: '41573000' },
  { name: 'فارس', city: 'اشکنان', code: '41472000' },
  { name: 'فارس', city: 'افزر', code: '41532000' },
  { name: 'فارس', city: 'اهل', code: '41472021' },
  { name: 'فارس', city: 'ایج', code: '41351021' },
  { name: 'فارس', city: 'باب انار', code: '41372021' },
  { name: 'فارس', city: 'بابامنیر', code: '41493021' },
  { name: 'فارس', city: 'بالاده', code: '41592021' },
  { name: 'فارس', city: 'بنارویه', code: '41465021' },
  { name: 'فارس', city: 'بیرم', code: '41592000' },
  { name: 'فارس', city: 'جنت شهر', code: '41381021' },
  { name: 'فارس', city: 'جویم', code: '41465000' },
  { name: 'فارس', city: 'چاه ورز', code: '41471024' },
  { name: 'فارس', city: 'خانه زنیان', code: '41312023' },
  { name: 'فارس', city: 'خانیمن', code: '41485023' },
  { name: 'فارس', city: 'خاوران', code: '41372022' },
  { name: 'فارس', city: 'خشت', code: '41443000' },
  { name: 'فارس', city: 'خوزی', code: '41476021' },
  { name: 'فارس', city: 'داریان', code: '41311021' },
  { name: 'فارس', city: 'دبیران', code: '41511021' },
  { name: 'فارس', city: 'دژکرد', code: '41362022' },
  { name: 'فارس', city: 'دوبرجی', code: '41384021' },
  { name: 'فارس', city: 'دوزه', code: '41373023' },
  { name: 'فارس', city: 'دهرم', code: '41522000' },
  { name: 'فارس', city: 'دهکویه', code: '41461022' },
  { name: 'فارس', city: 'رونیز', code: '41352000' },
  { name: 'فارس', city: 'زاهدشهر', code: '41413021' },
  { name: 'فارس', city: 'سعادت شهر', code: '41551021' },
  { name: 'فارس', city: 'سورمق', code: '41321025' },
  { name: 'فارس', city: 'ششده', code: '41412000' },
  { name: 'فارس', city: 'شهرپیر', code: '41512021' },
  { name: 'فارس', city: 'صغاد', code: '41321021' },
  { name: 'فارس', city: 'صفاشهر', code: '41331021' },
  { name: 'فارس', city: 'علامرودشت', code: '41473000' },
  { name: 'فارس', city: 'فاروق', code: '41482024' },
  { name: 'فارس', city: 'فال', code: '41572021' },
  { name: 'فارس', city: 'فدامی', code: '41384023' },
  { name: 'فارس', city: 'قطرویه', code: '41452021' },
  { name: 'فارس', city: 'کره ای', code: '41342021' },
  { name: 'فارس', city: 'کنارتخته', code: '41443021' },
  { name: 'فارس', city: 'کوهنجان', code: '41562000' },
  { name: 'فارس', city: 'گله دار', code: '41572000' },
  { name: 'فارس', city: 'لپویی', code: '41313021' },
  { name: 'فارس', city: 'لطیفی', code: '41461032' },
  { name: 'فارس', city: 'محمله', code: '41542000' },
  { name: 'فارس', city: 'مزایجان', code: '41341026' },
  { name: 'فارس', city: 'مصیری', code: '41581021' },
  { name: 'فارس', city: 'نوبندگان', code: '41414000' },
  { name: 'فارس', city: 'نوجین', code: '41521021' },
  { name: 'فارس', city: 'نودان', code: '41444023' },
  { name: 'فارس', city: 'وراوی', code: '41476000' },
  { name: 'فارس', city: 'داراب', code: '41380000' },
  { name: 'فارس', city: 'زرین دشت', code: '41510001' },
  { name: 'فارس', city: 'شیراز', code: '41310000' },
  { name: 'فارس', city: 'فیروزآباد', code: '41430000' },
  { name: 'فارس', city: 'لارستان', code: '41460000' },
  { name: 'فارس', city: 'مهر', code: '41570000' },
  { name: 'فارس', city: 'ایزدخواست', code: '41512000' },
  { name: 'فارس', city: 'حاجی آباد', code: '41510000' },
  { name: 'فارس', city: 'حسن اباد', code: '41363000' },
  { name: 'فارس', city: 'سده', code: '41362000' },
  { name: 'فارس', city: 'سیدان', code: '41482000' },
  { name: 'فارس', city: 'طسوج', code: '41612000' },
  { name: 'قزوین', city: 'آبیک', code: '15340000' },
  { name: 'قزوین', city: 'آوج', code: '15360000' },
  { name: 'قزوین', city: 'البرز', code: '15350000' },
  { name: 'قزوین', city: 'بویین زهرا', code: '15320000' },
  { name: 'قزوین', city: 'تاکستان', code: '15330000' },
  { name: 'قزوین', city: 'قزوین', code: '15310000' },
  { name: 'قزوین', city: 'ارداق', code: '15325022' },
  { name: 'قزوین', city: 'اسفرورین', code: '15332000' },
  { name: 'قزوین', city: 'اقبالیه', code: '15311021' },
  { name: 'قزوین', city: 'خاکعلی', code: '15342021' },
  { name: 'قزوین', city: 'دانسفهان', code: '15323022' },
  { name: 'قزوین', city: 'رازمیان', code: '15312024' },
  { name: 'قزوین', city: 'سگزآباد', code: '15321024' },
  { name: 'قزوین', city: 'سیردان', code: '15314027' },
  { name: 'قزوین', city: 'محمدیه', code: '15352000' },
  { name: 'قزوین', city: 'معلم کلایه', code: '15313023' },
  { name: 'قزوین', city: 'نرجه', code: '15331022' },
  { name: 'قزوین', city: 'آبگرم', code: '15362000' },
  { name: 'قزوین', city: 'الوند', code: '15351021' },
  { name: 'قزوین', city: 'خرمدشت', code: '15334000' },
  { name: 'قزوین', city: 'شال', code: '15324000' },
  { name: 'قم', city: 'قم', code: '14310000' },
  { name: 'قم', city: 'سلفچگان', code: '14311022' },
  { name: 'قم', city: 'قنوات', code: '14311024' },
  { name: 'کردستان', city: 'بانه', code: '73320000' },
  { name: 'کردستان', city: 'بیجار', code: '73330000' },
  { name: 'کردستان', city: 'دهگلان', code: '73410000' },
  { name: 'کردستان', city: 'دیواندره', code: '73350000' },
  { name: 'کردستان', city: 'سروآباد', code: '73390000' },
  { name: 'کردستان', city: 'سقز', code: '73340000' },
  { name: 'کردستان', city: 'سنندج', code: '73310000' },
  { name: 'کردستان', city: 'قروه', code: '73360000' },
  { name: 'کردستان', city: 'کامیاران', code: '73380000' },
  { name: 'کردستان', city: 'مریوان', code: '73370000' },
  { name: 'کردستان', city: 'آرمرده', code: '73322022' },
  { name: 'کردستان', city: 'اورامان تخت', code: '73392000' },
  { name: 'کردستان', city: 'بابارشانی', code: '73333021' },
  { name: 'کردستان', city: 'برده رشه', code: '73372021' },
  { name: 'کردستان', city: 'بلبان آباد', code: '73412000' },
  { name: 'کردستان', city: 'پیرتاج', code: '73333022' },
  { name: 'کردستان', city: 'توپ آغاج', code: '73331024' },
  { name: 'کردستان', city: 'چناره', code: '73373021' },
  { name: 'کردستان', city: 'دزج', code: '73363021' },
  { name: 'کردستان', city: 'دلبران', code: '73361023' },
  { name: 'کردستان', city: 'سریش آباد', code: '73362027' },
  { name: 'کردستان', city: 'سنته', code: '73342026' },
  { name: 'کردستان', city: 'شویشه', code: '73312021' },
  { name: 'کردستان', city: 'صاحب', code: '73342025' },
  { name: 'کردستان', city: 'کانی دینار', code: '73371023' },
  { name: 'کردستان', city: 'کانی سور', code: '73323022' },
  { name: 'کردستان', city: 'موچش', code: '73382000' },
  { name: 'کردستان', city: 'یاسوکند', code: '73332021' },
  { name: 'کرمان', city: 'ارزوییه', code: '45530000' },
  { name: 'کرمان', city: 'انار', code: '45520000' },
  { name: 'کرمان', city: 'بافت', code: '45330000' },
  { name: 'کرمان', city: 'بردسیر', code: '45340000' },
  { name: 'کرمان', city: 'بم', code: '45350000' },
  { name: 'کرمان', city: 'جیرفت', code: '45360000' },
  { name: 'کرمان', city: 'رابر', code: '45490000' },
  { name: 'کرمان', city: 'راور', code: '45320000' },
  { name: 'کرمان', city: 'رفسنجان', code: '45370000' },
  { name: 'کرمان', city: 'سیرجان', code: '45390000' },
  { name: 'کرمان', city: 'عنبرآباد', code: '45470000' },
  { name: 'کرمان', city: 'فهرج', code: '93311022' },
  { name: 'کرمان', city: 'قلعه گنج', code: '45450000' },
  { name: 'کرمان', city: 'کرمان', code: '45310000' },
  { name: 'کرمان', city: 'کوهبنان', code: '45460000' },
  { name: 'کرمان', city: 'کهنوج', code: '45420000' },
  { name: 'کرمان', city: 'نرماشیر', code: '45540000' },
  { name: 'کرمان', city: 'اندوهجرد', code: '45314021' },
  { name: 'کرمان', city: 'باغین', code: '45311022' },
  { name: 'کرمان', city: 'بروات', code: '45351021' },
  { name: 'کرمان', city: 'بزنجان', code: '45331021' },
  { name: 'کرمان', city: 'بلورد', code: '45391023' },
  { name: 'کرمان', city: 'بلوک', code: '45472021' },
  { name: 'کرمان', city: 'پاریز', code: '45392000' },
  { name: 'کرمان', city: 'جوپار', code: '45316022' },
  { name: 'کرمان', city: 'جوزم', code: '45411025' },
  { name: 'کرمان', city: 'چاه دادخدا', code: '45452000' },
  { name: 'کرمان', city: 'خانوک', code: '45381023' },
  { name: 'کرمان', city: 'خورسند', code: '45411028' },
  { name: 'کرمان', city: 'درب بهشت', code: '45363021' },
  { name: 'کرمان', city: 'دشتکار', code: '45341028' },
  { name: 'کرمان', city: 'دوساری', code: '45361036' },
  { name: 'کرمان', city: 'ده کهان', code: '45432024' },
  { name: 'کرمان', city: 'دهج', code: '45411021' },
  { name: 'کرمان', city: 'راین', code: '45313000' },
  { name: 'کرمان', city: 'رمشک', code: '45452021' },
  { name: 'کرمان', city: 'ریحان', code: '51341033' },
  { name: 'کرمان', city: 'زهکلوت', code: '45442021' },
  { name: 'کرمان', city: 'زیدآباد', code: '45391021' },
  { name: 'کرمان', city: 'سیریز', code: '45381028' },
  { name: 'کرمان', city: 'شهداد', code: '45314000' },
  { name: 'کرمان', city: 'کشکوییه', code: '45373000' },
  { name: 'کرمان', city: 'گلباف', code: '45315000' },
  { name: 'کرمان', city: 'گلزار', code: '45341021' },
  { name: 'کرمان', city: 'گنبکی', code: '45482000' },
  { name: 'کرمان', city: 'لاله زار', code: '45341026' },
  { name: 'کرمان', city: 'ماهان', code: '45316000' },
  { name: 'کرمان', city: 'محی آباد', code: '45316023' },
  { name: 'کرمان', city: 'مردهک', code: '45471028' },
  { name: 'کرمان', city: 'نجف شهر', code: '45391022' },
  { name: 'کرمان', city: 'نظام شهر', code: '45542021' },
  { name: 'کرمان', city: 'نگار', code: '45341022' },
  { name: 'کرمان', city: 'نودژ', code: '45432022' },
  { name: 'کرمان', city: 'هنزا', code: '45492000' },
  { name: 'کرمان', city: 'ریگان', code: '45480000' },
  { name: 'کرمان', city: 'زرند', code: '45380000' },
  { name: 'کرمان', city: 'شهربابک', code: '45410000' },
  { name: 'کرمان', city: 'فاریاب', code: '45550000' },
  { name: 'کرمان', city: 'منوجان', code: '45430000' },
  { name: 'کرمان', city: 'رودبار', code: '45440000' },
  { name: 'کرمان', city: 'مس سرچشمه', code: '45371023' },
  { name: 'کرمانشاه', city: 'پاوه', code: '71330000' },
  { name: 'کرمانشاه', city: 'ثلاث باباجانی', code: '71430000' },
  { name: 'کرمانشاه', city: 'جوانرود', code: '71340000' },
  { name: 'کرمانشاه', city: 'دالاهو', code: '71450000' },
  { name: 'کرمانشاه', city: 'روانسر', code: '71440000' },
  { name: 'کرمانشاه', city: 'سرپل ذهاب', code: '71350000' },
  { name: 'کرمانشاه', city: 'سنقر', code: '71360000' },
  { name: 'کرمانشاه', city: 'صحنه', code: '71380000' },
  { name: 'کرمانشاه', city: 'قصرشیرین', code: '71370000' },
  { name: 'کرمانشاه', city: 'کرمانشاه', code: '71310000' },
  { name: 'کرمانشاه', city: 'گیلانغرب', code: '71410000' },
  { name: 'کرمانشاه', city: 'هرسین', code: '71420000' },
  { name: 'کرمانشاه', city: 'ازگله', code: '71432000' },
  { name: 'کرمانشاه', city: 'بانوره', code: '71332026' },
  { name: 'کرمانشاه', city: 'باینگان', code: '71332000' },
  { name: 'کرمانشاه', city: 'بیستون', code: '71422000' },
  { name: 'کرمانشاه', city: 'حمیل', code: '71322000' },
  { name: 'کرمانشاه', city: 'ریجاب', code: '71451024' },
  { name: 'کرمانشاه', city: 'سرمست', code: '71412022' },
  { name: 'کرمانشاه', city: 'سطر', code: '71362021' },
  { name: 'کرمانشاه', city: 'سومار', code: '71372000' },
  { name: 'کرمانشاه', city: 'شاهو', code: '73381023' },
  { name: 'کرمانشاه', city: 'شروینه', code: '71341023' },
  { name: 'کرمانشاه', city: 'قلعه', code: '73361025' },
  { name: 'کرمانشاه', city: 'کوزران', code: '71312000' },
  { name: 'کرمانشاه', city: 'گهواره', code: '71452000' },
  { name: 'کرمانشاه', city: 'میان راهان', code: '71382021' },
  { name: 'کرمانشاه', city: 'نودشه', code: '71333021' },
  { name: 'کرمانشاه', city: 'نوسود', code: '71333000' },
  { name: 'کرمانشاه', city: 'هلشی', code: '71313021' },
  { name: 'کرمانشاه', city: 'کنگاور', code: '71390000' },
  { name: 'کهگیلویه وبویراحمد', city: 'باشت', code: '85370000' },
  { name: 'کهگیلویه وبویراحمد', city: 'بهمیی', code: '36472024' },
  { name: 'کهگیلویه وبویراحمد', city: 'دنا', code: '85340000' },
  { name: 'کهگیلویه وبویراحمد', city: 'مارگون', code: '85312000' },
  { name: 'کهگیلویه وبویراحمد', city: 'پاتاوه', code: '85342000' },
  { name: 'کهگیلویه وبویراحمد', city: 'چیتاب', code: '85343021' },
  { name: 'کهگیلویه وبویراحمد', city: 'دیشموک', code: '85324000' },
  { name: 'کهگیلویه وبویراحمد', city: 'سرفاریاب', code: '85362000' },
  { name: 'کهگیلویه وبویراحمد', city: 'سوق', code: '85321025' },
  { name: 'کهگیلویه وبویراحمد', city: 'سی سخت', code: '85341023' },
  { name: 'کهگیلویه وبویراحمد', city: 'قلعه رییسی', code: '85323022' },
  { name: 'کهگیلویه وبویراحمد', city: 'لیکک', code: '85352021' },
  { name: 'کهگیلویه وبویراحمد', city: 'بویراحمد', code: '85310000' },
  { name: 'کهگیلویه وبویراحمد', city: 'چرام', code: '85360000' },
  { name: 'کهگیلویه وبویراحمد', city: 'کهگیلویه', code: '85320000' },
  { name: 'کهگیلویه وبویراحمد', city: 'گچساران', code: '85330000' },
  { name: 'کهگیلویه وبویراحمد', city: 'لنده', code: '85380000' },
  { name: 'کهگیلویه وبویراحمد', city: 'بوستان', code: '85372000' },
  { name: 'کهگیلویه وبویراحمد', city: 'دوگنبدان', code: '85330000' },
  { name: 'کهگیلویه وبویراحمد', city: 'دهدشت', code: '85320001' },
  { name: 'کهگیلویه وبویراحمد', city: 'یاسوج', code: '85310000' },
  { name: 'گلستان', city: 'آزادشهر', code: '97390000' },
  { name: 'گلستان', city: 'آق قلا', code: '97380000' },
  { name: 'گلستان', city: 'بندرگز', code: '97370000' },
  { name: 'گلستان', city: 'ترکمن', code: '97320001' },
  { name: 'گلستان', city: 'رامیان', code: '97410000' },
  { name: 'گلستان', city: 'کردکوی', code: '97340000' },
  { name: 'گلستان', city: 'کلاله', code: '97420000' },
  { name: 'گلستان', city: 'گالیکش', code: '97450000' },
  { name: 'گلستان', city: 'گرگان', code: '97310000' },
  { name: 'گلستان', city: 'گمیشان', code: '97440000' },
  { name: 'گلستان', city: 'گنبدکاووس', code: '97350000' },
  { name: 'گلستان', city: 'مراوه تپه', code: '97430000' },
  { name: 'گلستان', city: 'اینچه برون', code: '97353021' },
  { name: 'گلستان', city: 'تاتارعلیا', code: '97411026' },
  { name: 'گلستان', city: 'خان ببین', code: '97412021' },
  { name: 'گلستان', city: 'دلند', code: '97411021' },
  { name: 'گلستان', city: 'دوزین', code: '97361025' },
  { name: 'گلستان', city: 'سرخنکلاته', code: '97313021' },
  { name: 'گلستان', city: 'سیجوال', code: '97321022' },
  { name: 'گلستان', city: 'فاضل آباد', code: '97332021' },
  { name: 'گلستان', city: 'قرق', code: '97313023' },
  { name: 'گلستان', city: 'گمیش تپه', code: '97441021' },
  { name: 'گلستان', city: 'نوده خاندوز', code: '97392022' },
  { name: 'گلستان', city: 'علی آباد کتول', code: '97330000' },
  { name: 'گلستان', city: 'مینودشت', code: '97360000' },
  { name: 'گلستان', city: 'بندرترکمن', code: '97320000' },
  { name: 'گلستان', city: 'نوکنده', code: '97372000' },
  { name: 'گیلان', city: 'آستارا', code: '54320000' },
  { name: 'گیلان', city: 'آستانه اشرفیه', code: '54340000' },
  { name: 'گیلان', city: 'املش', code: '54370000' },
  { name: 'گیلان', city: 'بندرانزلی', code: '54330000' },
  { name: 'گیلان', city: 'رشت', code: '54310000' },
  { name: 'گیلان', city: 'رضوانشهر', code: '54410000' },
  { name: 'گیلان', city: 'رودسر', code: '54360000' },
  { name: 'گیلان', city: 'سیاهکل', code: '54460000' },
  { name: 'گیلان', city: 'صومعه سرا', code: '54380000' },
  { name: 'گیلان', city: 'طوالش', code: '54390000' },
  { name: 'گیلان', city: 'فومن', code: '54430000' },
  { name: 'گیلان', city: 'لنگرود', code: '54470000' },
  { name: 'گیلان', city: 'ماسال', code: '54420000' },
  { name: 'گیلان', city: 'اسالم', code: '54392000' },
  { name: 'گیلان', city: 'اطاقور', code: '54473000' },
  { name: 'گیلان', city: 'بره سر', code: '54354021' },
  { name: 'گیلان', city: 'پره سر', code: '54412000' },
  { name: 'گیلان', city: 'توتکابن', code: '54352022' },
  { name: 'گیلان', city: 'جیرنده', code: '54353021' },
  { name: 'گیلان', city: 'چابکسر', code: '54363000' },
  { name: 'گیلان', city: 'چوبر', code: '54442021' },
  { name: 'گیلان', city: 'حویق', code: '54393021' },
  { name: 'گیلان', city: 'خشکبیجار', code: '54313000' },
  { name: 'گیلان', city: 'خمام', code: '54312000' },
  { name: 'گیلان', city: 'دیلمان', code: '54462000' },
  { name: 'گیلان', city: 'رانکوه', code: '54372000' },
  { name: 'گیلان', city: 'رودبنه', code: '54452000' },
  { name: 'گیلان', city: 'سنگر', code: '54314000' },
  { name: 'گیلان', city: 'شلمان', code: '54472021' },
  { name: 'گیلان', city: 'کلاچای', code: '54362000' },
  { name: 'گیلان', city: 'کوچصفهان', code: '54315000' },
  { name: 'گیلان', city: 'کومله', code: '54472000' },
  { name: 'گیلان', city: 'گوراب زرمیخ', code: '54382021' },
  { name: 'گیلان', city: 'لشت نشاء', code: '54316000' },
  { name: 'گیلان', city: 'لوشان', code: '54351023' },
  { name: 'گیلان', city: 'لولمان', code: '54315022' },
  { name: 'گیلان', city: 'لوندویل', code: '54321023' },
  { name: 'گیلان', city: 'لیسار', code: '54393022' },
  { name: 'گیلان', city: 'ماسوله', code: '54431021' },
  { name: 'گیلان', city: 'ماکلوان', code: '54431029' },
  { name: 'گیلان', city: 'مرجقل', code: '54383021' },
  { name: 'گیلان', city: 'منجیل', code: '54351021' },
  { name: 'گیلان', city: 'واجارگاه', code: '54361021' },
  { name: 'گیلان', city: 'رودبار', code: '54350000' },
  { name: 'گیلان', city: 'شفت', code: '54440000' },
  { name: 'گیلان', city: 'لاهیجان', code: '54450000' },
  { name: 'گیلان', city: 'احمدسرگوراب', code: '54442000' },
  { name: 'گیلان', city: 'هشتپر (تالش)', code: '54391021' },
  { name: 'لرستان', city: 'ازنا', code: '81350000' },
  { name: 'لرستان', city: 'الیگودرز', code: '81340000' },
  { name: 'لرستان', city: 'بروجرد', code: '81360000' },
  { name: 'لرستان', city: 'پلدختر', code: '81330000' },
  { name: 'لرستان', city: 'دلفان', code: '81370000' },
  { name: 'لرستان', city: 'دورود', code: '81380000' },
  { name: 'لرستان', city: 'رومشکان', code: '81392000' },
  { name: 'لرستان', city: 'سلسله', code: '81320000' },
  { name: 'لرستان', city: 'اشترینان', code: '81362000' },
  { name: 'لرستان', city: 'الشتر', code: '81321021' },
  { name: 'لرستان', city: 'چالانچولان', code: '81382022' },
  { name: 'لرستان', city: 'چقابل', code: '81392021' },
  { name: 'لرستان', city: 'چم پلک', code: '81412000' },
  { name: 'لرستان', city: 'چمن سلطان', code: '81341022' },
  { name: 'لرستان', city: 'درب گنبد', code: '81393022' },
  { name: 'لرستان', city: 'سراب دوره', code: '81411021' },
  { name: 'لرستان', city: 'سوری', code: '81392022' },
  { name: 'لرستان', city: 'معمولان', code: '81332000' },
  { name: 'لرستان', city: 'ویسیان', code: '81413000' },
  { name: 'لرستان', city: 'هفت چشمه', code: '81331036' },
  { name: 'لرستان', city: 'خرم آباد', code: '81310000' },
  { name: 'لرستان', city: 'کوهدشت', code: '81390000' },
  { name: 'لرستان', city: 'فیروزآباد', code: '81322000' },
  { name: 'مازندران', city: 'آمل', code: '16320000' },
  { name: 'مازندران', city: 'بابل', code: '16330000' },
  { name: 'مازندران', city: 'بابلسر', code: '16340000' },
  { name: 'مازندران', city: 'بهشهر', code: '16350000' },
  { name: 'مازندران', city: 'تنکابن', code: '16380000' },
  { name: 'مازندران', city: 'جویبار', code: '16430000' },
  { name: 'مازندران', city: 'چالوس', code: '16460000' },
  { name: 'مازندران', city: 'رامسر', code: '16390000' },
  { name: 'مازندران', city: 'ساری', code: '16310000' },
  { name: 'مازندران', city: 'سوادکوه', code: '16410000' },
  { name: 'مازندران', city: 'فریدونکنار', code: '16480000' },
  { name: 'مازندران', city: 'قایم شهر', code: '16420000' },
  { name: 'مازندران', city: 'کلاردشت', code: '16462000' },
  { name: 'مازندران', city: 'میاندورود', code: '16520000' },
  { name: 'مازندران', city: 'نکا', code: '16470000' },
  { name: 'مازندران', city: 'نور', code: '16440000' },
  { name: 'مازندران', city: 'نوشهر', code: '16450000' },
  { name: 'مازندران', city: 'آلاشت', code: '16411022' },
  { name: 'مازندران', city: 'امیرکلا', code: '16331022' },
  { name: 'مازندران', city: 'بهنمیر', code: '16343000' },
  { name: 'مازندران', city: 'پایین هولار', code: '16311029' },
  { name: 'مازندران', city: 'پل سفید', code: '16411021' },
  { name: 'مازندران', city: 'پول', code: '16452024' },
  { name: 'مازندران', city: 'چمستان', code: '16443000' },
  { name: 'مازندران', city: 'دابودشت', code: '16323000' },
  { name: 'مازندران', city: 'رینه', code: '16322022' },
  { name: 'مازندران', city: 'زرگرمحله', code: '16333023' },
  { name: 'مازندران', city: 'سرخرود', code: '16372000' },
  { name: 'مازندران', city: 'شیرگاه', code: '16412000' },
  { name: 'مازندران', city: 'شیرود', code: '16381032' },
  { name: 'مازندران', city: 'فریم', code: '16313023' },
  { name: 'مازندران', city: 'کجور', code: '16452000' },
  { name: 'مازندران', city: 'کلارآباد', code: '16512000' },
  { name: 'مازندران', city: 'کوهی خیل', code: '16432023' },
  { name: 'مازندران', city: 'کیاسر', code: '16312021' },
  { name: 'مازندران', city: 'کیاکلا', code: '16530000' },
  { name: 'مازندران', city: 'گتاب', code: '16336000' },
  { name: 'مازندران', city: 'گزنک', code: '16322021' },
  { name: 'مازندران', city: 'مرزن آباد', code: '16462021' },
  { name: 'مازندران', city: 'مرزیکلا', code: '16332021' },
  { name: 'مازندران', city: 'نشتارود', code: '16512022' },
  { name: 'مازندران', city: 'گلوگاه', code: '16490000' },
  { name: 'مازندران', city: 'سلمان شهر', code: '16512021' },
  { name: 'مرکزی', city: 'آشتیان', code: '51320000' },
  { name: 'مرکزی', city: 'اراک', code: '51310000' },
  { name: 'مرکزی', city: 'تفرش', code: '51330000' },
  { name: 'مرکزی', city: 'خمین', code: '51340000' },
  { name: 'مرکزی', city: 'خنداب', code: '51420000' },
  { name: 'مرکزی', city: 'دلیجان', code: '51350000' },
  { name: 'مرکزی', city: 'ساوه', code: '51360000' },
  { name: 'مرکزی', city: 'شازند', code: '51370000' },
  { name: 'مرکزی', city: 'فراهان', code: '51430000' },
  { name: 'مرکزی', city: 'کمیجان', code: '51390000' },
  { name: 'مرکزی', city: 'محلات', code: '51380000' },
  { name: 'مرکزی', city: 'آوه', code: '51361040' },
  { name: 'مرکزی', city: 'پرندک', code: '11411025' },
  { name: 'مرکزی', city: 'توره', code: '51372023' },
  { name: 'مرکزی', city: 'جاورسیان', code: '51422000' },
  { name: 'مرکزی', city: 'خنجین', code: '51432000' },
  { name: 'مرکزی', city: 'غرق آباد', code: '51364021' },
  { name: 'مرکزی', city: 'فرمهین', code: '51431021' },
  { name: 'مرکزی', city: 'قورچی باشی', code: '51342022' },
  { name: 'مرکزی', city: 'مامونیه', code: '51411021' },
  { name: 'مرکزی', city: 'مهاجران', code: '51372025' },
  { name: 'مرکزی', city: 'میلاجرد', code: '51392000' },
  { name: 'مرکزی', city: 'نراق', code: '51351022' },
  { name: 'مرکزی', city: 'نوبران', code: '51364000' },
  { name: 'مرکزی', city: 'نیمور', code: '51381023' },
  { name: 'مرکزی', city: 'هندودر', code: '51373000' },
  { name: 'مرکزی', city: 'زرندیه', code: '51410000' },
  { name: 'مرکزی', city: 'آستانه', code: '51371021' },
  { name: 'مرکزی', city: 'تلخاب', code: '51432021' },
  { name: 'هرمزگان', city: 'ابوموسی', code: '64340000' },
  { name: 'هرمزگان', city: 'بستک', code: '64390000' },
  { name: 'هرمزگان', city: 'بشاگرد', code: '64440000' },
  { name: 'هرمزگان', city: 'بندرعباس', code: '64310000' },
  { name: 'هرمزگان', city: 'بندرلنگه', code: '64330000' },
  { name: 'هرمزگان', city: 'جاسک', code: '64320000' },
  { name: 'هرمزگان', city: 'خمیر', code: '64410000' },
  { name: 'هرمزگان', city: 'سیریک', code: '64430000' },
  { name: 'هرمزگان', city: 'قشم', code: '64370000' },
  { name: 'هرمزگان', city: 'میناب', code: '64380000' },
  { name: 'هرمزگان', city: 'بالاشهر', code: '64361027' },
  { name: 'هرمزگان', city: 'بندرجاسک', code: '64320001' },
  { name: 'هرمزگان', city: 'بندزرک', code: '64381023' },
  { name: 'هرمزگان', city: 'تخت', code: '64311025' },
  { name: 'هرمزگان', city: 'جناح', code: '64392000' },
  { name: 'هرمزگان', city: 'درگهان', code: '64371022' },
  { name: 'هرمزگان', city: 'دهبارز', code: '64361021' },
  { name: 'هرمزگان', city: 'رویدر', code: '64412000' },
  { name: 'هرمزگان', city: 'زیارتعلی', code: '64362021' },
  { name: 'هرمزگان', city: 'سرگز', code: '64352022' },
  { name: 'هرمزگان', city: 'سندرک', code: '64383000' },
  { name: 'هرمزگان', city: 'سوزا', code: '64372021' },
  { name: 'هرمزگان', city: 'طبل', code: '64372024' },
  { name: 'هرمزگان', city: 'فارغان', code: '64352000' },
  { name: 'هرمزگان', city: 'قلعه قاضی', code: '64311024' },
  { name: 'هرمزگان', city: 'کنگ', code: '64331021' },
  { name: 'هرمزگان', city: 'کوشکنار', code: '64422000' },
  { name: 'هرمزگان', city: 'کوهستک', code: '64432021' },
  { name: 'هرمزگان', city: 'کیش', code: '64334000' },
  { name: 'هرمزگان', city: 'لمزان', code: '64331028' },
  { name: 'هرمزگان', city: 'لیردف', code: '64321022' },
  { name: 'هرمزگان', city: 'هرمز', code: '64371021' },
  { name: 'هرمزگان', city: 'پارسیان', code: '64420000' },
  { name: 'هرمزگان', city: 'حاجی اباد', code: '64350000' },
  { name: 'هرمزگان', city: 'رودان', code: '64360000' },
  { name: 'هرمزگان', city: 'بیکاء', code: '64361024' },
  { name: 'هرمزگان', city: 'فین', code: '64313000' },
  { name: 'هرمزگان', city: 'گوهران', code: '64443000' },
  { name: 'همدان', city: 'بهار', code: '75330000' },
  { name: 'همدان', city: 'تویسرکان', code: '75340000' },
  { name: 'همدان', city: 'رزن', code: '75350000' },
  { name: 'همدان', city: 'فامنین', code: '75390000' },
  { name: 'همدان', city: 'کبودرآهنگ', code: '75360000' },
  { name: 'همدان', city: 'ملایر', code: '75370000' },
  { name: 'همدان', city: 'همدان', code: '75310000' },
  { name: 'همدان', city: 'آجین', code: '75321027' },
  { name: 'همدان', city: 'ازندریان', code: '75372026' },
  { name: 'همدان', city: 'برزول', code: '75381028' },
  { name: 'همدان', city: 'جورقان', code: '75311023' },
  { name: 'همدان', city: 'جوکار', code: '75372000' },
  { name: 'همدان', city: 'دمق', code: '75352023' },
  { name: 'همدان', city: 'سامن', code: '75373000' },
  { name: 'همدان', city: 'شیرین سو', code: '75362000' },
  { name: 'همدان', city: 'فرسفج', code: '75342021' },
  { name: 'همدان', city: 'قهاوند', code: '75312022' },
  { name: 'همدان', city: 'گیان', code: '75381027' },
  { name: 'همدان', city: 'لالجین', code: '75333000' },
  { name: 'همدان', city: 'مریانج', code: '75311022' },
  { name: 'همدان', city: 'مهاجران', code: '75333023' },
  { name: 'همدان', city: 'اسدآباد', code: '75320000' },
  { name: 'همدان', city: 'نهاوند', code: '75380000' },
  { name: 'همدان', city: 'سرکان', code: '75341022' },
  { name: 'همدان', city: 'صالح آباد', code: '75332000' },
  { name: 'همدان', city: 'قروه درگزین', code: '75353000' },
  { name: 'همدان', city: 'گل تپه', code: '75363000' },
  { name: 'یزد', city: 'ابرکوه', code: '93360000' },
  { name: 'یزد', city: 'اردکان', code: '93330000' },
  { name: 'یزد', city: 'اشکذر', code: '93320000' },
  { name: 'یزد', city: 'بافق', code: '93340000' },
  { name: 'یزد', city: 'بهاباد', code: '93420000' },
  { name: 'یزد', city: 'تفت', code: '93350000' },
  { name: 'یزد', city: 'خاتم', code: '93410000' },
  { name: 'یزد', city: 'مهریز', code: '93370000' },
  { name: 'یزد', city: 'میبد', code: '93380000' },
  { name: 'یزد', city: 'یزد', code: '93310000' },
  { name: 'یزد', city: 'احمدآباد', code: '93331022' },
  { name: 'یزد', city: 'بخ', code: '93352022' },
  { name: 'یزد', city: 'بفروییه', code: '93381021' },
  { name: 'یزد', city: 'حمیدیا', code: '93311026' },
  { name: 'یزد', city: 'خضرآباد', code: '93322000' },
  { name: 'یزد', city: 'زارچ', code: '93312000' },
  { name: 'یزد', city: 'شاهدیه', code: '93311025' },
  { name: 'یزد', city: 'عقدا', code: '93332000' },
  { name: 'یزد', city: 'مروست', code: '93412000' },
  { name: 'یزد', city: 'مهردشت', code: '21472000' },
  { name: 'یزد', city: 'ندوشن', code: '93322022' },
  { name: 'یزد', city: 'نیر', code: '91320000' },
  { name: 'یزد', city: 'هرات', code: '93411021' }
];

export default provinces;
