import * as types from 'redux/actions/types';

const INITIAL_STATE = {
  root: [],
  details: []
};

const getNewState = (state = {}, payload = []) => {
  return {
    ...state,
    details: payload,
    root: payload.map(({ name, _id, enName }) => ({ name, _id, enName }))
  };
};

export const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.getCategoriesSuccess:
      return getNewState(state, action.payload);
    default:
      return state;
  }
};
