import * as types from 'redux/actions/types';
import { deleteData, updateData } from '../utils';

const INITIAL_STATE = {
  visitors: [],
  reviews: [],
  messages: [],
  inquiries: [],
  creditSales: [],
  staffs: [],
  vCount: 0,
  rCount: 0,
  mCount: 0,
  iCount: 0,
  sCount: 0,
  cCount: 0
};

export const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.getSiteUsersSuccess:
      return {
        ...state,
        visitors: action.payload.data,
        vCount: action.payload.count
      };
    case types.getStaffsSuccess:
      return {
        ...state,
        staffs: action.payload.data,
        sCount: action.payload.count
      };
    case types.TYPES.reviews.GET.success:
      return {
        ...state,
        reviews: action.payload.data,
        rCount: action.payload.count
      };
    case types.TYPES.messages.GET.success:
      return {
        ...state,
        messages: action.payload.data,
        mCount: action.payload.count
      };
    case types.TYPES.inquiries.GET.success:
      return {
        ...state,
        inquiries: action.payload.data,
        iCount: action.payload.count
      };
    case types.TYPES.credits.GET.success:
      return {
        ...state,
        creditSales: action.payload.data,
        cCount: action.payload.count
      };
    // D: Staffs
    case types.deleteStaffSuccess:
      return deleteData(state, action.payload, 'staffs', 'sCount');
    // D: Reviews
    case types.TYPES.reviews.DELETE.success:
      return deleteData(state, action.payload, 'reviews', 'rCount');
    // D: Messages
    case types.TYPES.messages.DELETE.success:
      return deleteData(state, action.payload, 'messages', 'mCount');
    // D: Inquiry
    case types.TYPES.inquiries.DELETE.success:
      return deleteData(state, action.payload, 'inquiries', 'iCount');
    case types.TYPES.credits.DELETE.success:
      return deleteData(state, action.payload, 'creditSales', 'cCount');
    // U: Review
    case types.TYPES.reviews.UPDATE.success:
      return updateData(state, action.payload, 'reviews', 'rCount');
    // U: Message
    case types.TYPES.messages.UPDATE.success:
      return updateData(state, action.payload, 'messages', 'mCount');
    // U: Inquiry
    case types.TYPES.inquiries.UPDATE.success:
      return updateData(state, action.payload, 'inquiries', 'iCount');
    case types.TYPES.credits.UPDATE.success:
      return updateData(state, action.payload, 'creditSales', 'cCount');
    // U: Staff
    case types.updateStaffSuccess:
      return updateData(state, action.payload, 'staffs', 'sCount');
    default:
      return state;
  }
};
