/**
 * @augments (key, data, expiresInHours = 24)
 * @description Cache data in localStorage with expire date (default 24 hours)
 */
export const setLocalStorageItem = (key, data, expiresInHours = 24) => {
  const item = {
    data,
    expiredAt: Date.now() + 1000 * 60 * 60 * expiresInHours // default 24 hours
  };

  localStorage.setItem(key, JSON.stringify(item));
};

/**
 * @augments (key: the key of the item)
 * @description Retrieve data from the localStorage with the specified key
 */
export const getLocalStorageItem = key => {
  let item = localStorage.getItem(key);

  if (!item) return null;

  item = JSON.parse(item);
  if (item.expiredAt && Date.now() > item.expiredAt) {
    // If the item is expired, delete the item from storage and return null
    localStorage.removeItem(key);
    return null;
  }

  // If data is an array and it contains no items, return null
  if (Array.isArray(item.data) && item.data.length === 0) return null;

  return item.data;
};
