import { toastr } from 'react-redux-toastr';
import asreahanApi from 'api/asreahanApi';
import errorHandler from '../errorHandler';
import history from 'history.js';
import * as types from '../types';

export const getPatterns = params => {
  return async dispatch => {
    try {
      dispatch({ type: types.getPatternsRequest });
      const { data } = await asreahanApi.get('/patterns', {
        params
      });
      dispatch({
        type: types.getPatternsSuccess,
        payload: { data: data.data.patterns, count: data.totalDocs }
      });
    } catch (error) {
      errorHandler(error, dispatch, types.getPatternsFailure);
    }
  };
};

export const deletePattern = id => {
  return async dispatch => {
    try {
      dispatch({ type: types.deletePatternRequest });
      await asreahanApi.delete(`/patterns/${id}`);
      dispatch({
        type: types.deletePatternSuccess,
        payload: id
      });
    } catch (error) {
      errorHandler(error, dispatch, types.deletePatternFailure);
    }
  };
};

export const updatePattern = (fieldsToUpdate, id) => {
  return async dispatch => {
    try {
      dispatch({ type: types.updatePatternRequest });
      const { data } = await asreahanApi.patch(
        `/patterns/${id}`,
        fieldsToUpdate
      );
      dispatch({
        type: types.updatePatternSuccess,
        payload: data.data.pattern
      });
    } catch (error) {
      errorHandler(error, dispatch, types.updatePatternFailure);
    }
  };
};

export const addProductPatterns = (data, redirect = false) => {
  return async dispatch => {
    try {
      dispatch({ type: types.addProductPatternsRequest });
      await asreahanApi.post('/patterns', data);
      dispatch({
        type: types.addProductPatternsSuccess
      });
      toastr.success('Success', 'الگوی جدید با موفقیت ایجاد شد');
      if (redirect) history.push('/admin/product-patterns');
    } catch (error) {
      errorHandler(error, dispatch, types.addProductPatternsFailure);
    }
  };
};

export const uploadProductPhotos = (id, files) => {
  return async dispatch => {
    try {
      dispatch({ type: types.uploadPhotosRequest });

      const formData = new FormData();
      if (files.photos) formData.append('photos', files.photos[0]);

      await asreahanApi.patch(`/patterns/${id}/photos`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch({
        type: types.uploadPhotosSuccess
      });
      toastr.success('Success', 'تصاویر با موفقیت ثبت شد');
    } catch (error) {
      errorHandler(error, dispatch, types.uploadPhotosFailure);
    }
  };
};

export const editProductPattern = (id, formValues) => {
  return async dispatch => {
    try {
      dispatch({ type: types.editProductPatternRequest });
      await asreahanApi.patch(`/patterns/${id}`, formValues);
      dispatch({
        type: types.editProductPatternSuccess
      });
      toastr.success('Success', 'اطلاعات با موفقیت بروز‌رسانی شد');
      history.push('/admin/product-patterns');
    } catch (error) {
      errorHandler(error, dispatch, types.editProductPatternFailure);
    }
  };
};

export const searchPatterns = (params = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: types.searchPatternsRequest });
      const { data } = await asreahanApi.get('/patterns/search', {
        params
      });
      dispatch({
        type: types.searchPatternsSuccess,
        payload: { data: data.data.patterns, count: data.totalDocs }
      });
    } catch (error) {
      errorHandler(error, dispatch, types.searchPatternsFailure);
    }
  };
};

export const updatePatternsQuery = (
  queryName,
  value,
  type = 'ADD'
) => dispatch => {
  dispatch({
    type: type === 'ADD' ? types.addQueryParams : types.removeQueryParams,
    payload: { queryName, value }
  });
};

export const setPatternsPaginate = params => {
  return {
    type: types.setQueryPaginate,
    payload: params
  };
};

export const setPatternCategoryId = categoryId => {
  return {
    type: types.setPatternCategoryId,
    payload: categoryId
  };
};
