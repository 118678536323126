import React from 'react';

import { sidebarList } from 'routes/admin';
import ListItem from '../ListItem';
import ProductItems from './ProductItems';
import UserItems from './UserItems';

const _filterRoutes = (routes = [], user) =>
  routes.filter(
    ({ resource }) =>
      user.role === 'admin' || user.permissions?.includes(resource)
  );

const AdminItems = ({ user = {} }) => {
  return (
    <React.Fragment>
      {sidebarList.map(
        ({ path, title, resource, icon: Icon, nested, children }, i) => {
          //  Users management
          if (nested && title === 'مدیریت کاربران') {
            const items = _filterRoutes(children, user);
            return items.length ? (
              <UserItems icon={Icon} key={i} items={items} />
            ) : null;
          }
          //  Products management
          if (nested && title === 'مدیریت محصولات') {
            const items = _filterRoutes(children, user);

            return items.length ? (
              <ProductItems icon={Icon} key={i} items={items} />
            ) : null;
          }
          // Check is the user has permission
          if (user.role !== 'admin' && !user.permissions?.includes(resource))
            return null;
          // others
          return (
            <ListItem path={path} key={i} title={title}>
              <Icon />
            </ListItem>
          );
        }
      )}
    </React.Fragment>
  );
};

export default AdminItems;
