import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Badge from '@material-ui/core/Badge';

import { useStyles } from '../styles';

const NestedList = ({
  children,
  text,
  open,
  setOpen,
  icon: Icon,
  badgeContent = 0
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem className={classes.item} button onClick={() => setOpen(!open)}>
        <ListItemIcon className={classes.icon}>
          <Badge
            badgeContent={badgeContent}
            className="badge-anim"
            color="primary"
          >
            <Icon />
          </Badge>
        </ListItemIcon>
        <ListItemText className={classes.text} primary={text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        className={classes.collapse}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default NestedList;
