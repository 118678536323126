import { toastr } from 'react-redux-toastr';
import history from 'history.js';
import * as types from '../types';

const globalErrHandler = (error, dispatch, type) => {
  // Check if response is defined in error object
  if (error.response) {
    const {
      status,
      data: { message }
    } = error.response;
    // Display specified message
    dispatch({ type, payload: { message, status } });
    toastr.error('خطا', message);

    switch (status) {
      case 404:
        history.push('/404');
        return;
      case 401:
        dispatch({
          type: types.logoutUserSuccess
        });
        localStorage.removeItem('token');
        history.push('/login');
        return;
      default:
        return;
    }
  }
  // If the response is not defined, then display generic error message
  dispatch({ type, payload: { message: error, status: 500 } });
  toastr.error('خطا', 'خطا در برقراری ارتباط با سرور');
};

export default globalErrHandler;
