export const deleteData = (state, id, field = 'data', countField = 'count') => {
  const newData = state[field].filter(item => item._id !== id);
  return { ...state, [field]: newData, [countField]: state[countField] - 1 };
};

export const updateData = (state, newItem, field = 'data') => {
  const newData = state[field].map(el =>
    el._id === newItem._id ? { ...el, ...newItem } : el
  );
  return { ...state, [field]: newData };
};
