import React from 'react';

import NestedList from '../../NestedList';
import ListItem from '../../ListItem';

const ProductItems = ({ items = [], icon }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <NestedList text="مدیریت محصولات" open={open} setOpen={setOpen} icon={icon}>
      {items.map(({ path, title, icon: Icon }, i) => (
        <ListItem path={path} title={title} key={i}>
          <Icon />
        </ListItem>
      ))}
    </NestedList>
  );
};

export default ProductItems;
