import { toastr } from 'react-redux-toastr';
import asreahanApi from 'api/asreahanApi';
import errorHandler from '../errorHandler';
import * as types from '../types';

export const getProductsStats = () => {
  return async dispatch => {
    try {
      dispatch({ type: types.getProductsStatsRequest });
      const { data } = await asreahanApi.get('/products/stats');
      dispatch({
        type: types.getProductsStatsSuccess,
        payload: data.data
      });
    } catch (error) {
      errorHandler(error, dispatch, types.getProductsStatsFailure);
    }
  };
};

export const searchUserProducts = (params = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: types.searchUserProductsRequest });
      const { data } = await asreahanApi.get('/products/search/my-products', {
        params
      });
      dispatch({
        type: types.searchUserProductsSuccess,
        payload: { data: data.data.products, count: data.totalDocs }
      });
    } catch (error) {
      errorHandler(error, dispatch, types.searchUserProductsFailure);
    }
  };
};

export const addProductToList = (patternId, data) => {
  return async dispatch => {
    try {
      dispatch({ type: types.addPatternToListRequest });
      await asreahanApi.post(`/products/add-to-list/${patternId}`, data);
      dispatch({
        type: types.addPatternToListSuccess
      });
      // For updating redux state !
      dispatch({
        type: types.removeQueryParams,
        payload: { queryName: 'category', value: 'x' }
      });
      toastr.success('Success', 'محصول با موفقیت به لیست فروش اضافه شد');
    } catch (error) {
      errorHandler(error, dispatch, types.addPatternToListFailure);
    }
  };
};

export const updateProductsPrices = (data, cb) => {
  return async dispatch => {
    try {
      dispatch({ type: types.groupEditProductsRequest });
      await asreahanApi.patch(`/products/update-prices`, data);
      dispatch({
        type: types.groupEditProductsSuccess
      });
      if (cb && typeof cb === 'function') cb();
      toastr.success('Success', 'اطلاعات با موفقیت بروز‌رسانی شد');
    } catch (error) {
      errorHandler(error, dispatch, types.groupEditProductsFailure);
    }
  };
};

export const deleteProduct = id => {
  return async dispatch => {
    try {
      dispatch({ type: types.deleteProductRequest });
      await asreahanApi.delete(`/products/${id}`);
      dispatch({
        type: types.deleteProductSuccess,
        payload: id
      });
    } catch (error) {
      errorHandler(error, dispatch, types.deleteProductFailure);
    }
  };
};

export const updateProduct = (fieldsToUpdate, id) => {
  return async dispatch => {
    try {
      dispatch({ type: types.updateProductRequest });
      await asreahanApi.patch(`/products/${id}`, fieldsToUpdate);
      dispatch({
        type: types.updateProductSuccess,
        payload: {
          ...fieldsToUpdate,
          _id: id,
          updatedAt: new Date().toISOString()
        }
      });
    } catch (error) {
      errorHandler(error, dispatch, types.updateProductFailure);
    }
  };
};

export const updateProductPrice = (fieldsToUpdate, id) => {
  return async dispatch => {
    try {
      dispatch({ type: types.updateProductPriceRequest });
      await asreahanApi.patch(`/products/${id}/update-price`, fieldsToUpdate);
      dispatch({
        type: types.updateProductPriceSuccess,
        payload: {
          ...fieldsToUpdate,
          _id: id,
          updatedAt: new Date().toISOString()
        }
      });
    } catch (error) {
      errorHandler(error, dispatch, types.updateProductPriceFailure);
    }
  };
};

export const getSellerLoadingAreas = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.getLoadingAreasRequest });

      const { currentUser } = getState().user;

      const { data } = await asreahanApi.get(
        `/products/sellers/${currentUser?._id}`
      );
      dispatch({
        type: types.getLoadingAreasSuccess,
        payload: [...new Set(data.data.sellers[0]?.loadingAreas || [])]
      });
    } catch (error) {
      errorHandler(error, dispatch, types.getLoadingAreasFailure);
    }
  };
};

export const updateProductsQuery = (queryName, value) => {
  return {
    type: types.updateProductsQuery,
    payload: { queryName, value }
  };
};

export const resetProductsForm = () => {
  return {
    type: types.resetProductsForm
  };
};
