import { toastr } from 'react-redux-toastr';
import { persistor } from '../../store';
import asreahanApi from 'api/asreahanApi';
import errorHandler from '../errorHandler';
import history from 'history.js';
import * as types from '../types';
import { RESOURCES } from '../types/resources';
import { getAll, updateOne, deleteOne, createOne } from '../baseActions';

// TODO: Refactor redux actions management (in progress...)

export const login = formData => {
  return async dispatch => {
    try {
      dispatch({ type: types.loginUserRequest });
      const { data } = await asreahanApi.post('/auth/login', formData);
      const {
        data: { user },
        token
      } = data;
      // If users with 'user' role are not allowed to login!
      if (user.role === 'user') return history.push('/unauthorized');

      // Grand access the user
      dispatch({
        type: types.loginUserSuccess,
        payload: user
      });
      localStorage.setItem('token', token);
      toastr.success('', 'به پنل کاربری خوش آمدید');
      history.push('/home');
    } catch (error) {
      errorHandler(error, dispatch, types.loginUserFailure);
    }
  };
};

export const logout = () => {
  return async dispatch => {
    try {
      dispatch({ type: types.logoutUserRequest });
      await asreahanApi.get('/auth/logout');
      dispatch({
        type: types.logoutUserSuccess
      });
      localStorage.removeItem('token');
      persistor.purge();
      history.push('/login');
    } catch (error) {
      errorHandler(error, dispatch, types.logoutUserFailure);
    }
  };
};

export const addSeller = userFields => {
  return async dispatch => {
    try {
      dispatch({ type: types.addSellerRequest });
      await asreahanApi.post('/users', userFields);
      dispatch({
        type: types.addSellerSuccess
      });
      toastr.success('Success', 'فروشنده جدید با موفقیت ایجاد شد');
    } catch (error) {
      errorHandler(error, dispatch, types.addSellerFailure);
    }
  };
};

const INITIAL_PARAMS = {
  select: 'email,mobile,createdAt,name,isVerified'
};
export const getSiteUsers = (params = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: types.getSiteUsersRequest });
      const { data } = await asreahanApi.get('/users', {
        params: { ...INITIAL_PARAMS, ...params, role: 'user' }
      });
      dispatch({
        type: types.getSiteUsersSuccess,
        payload: { data: data.data.users, count: data.totalDocs }
      });
    } catch (error) {
      errorHandler(error, dispatch, types.getSiteUsersFailure);
    }
  };
};

export const getStaffs = (params = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: types.getStaffsRequest });
      const { data } = await asreahanApi.get('/users', {
        params: {
          select: 'email,mobile,name,permissions',
          ...params,
          role: 'staff'
        }
      });
      dispatch({
        type: types.getStaffsSuccess,
        payload: { data: data.data.users, count: data.totalDocs }
      });
    } catch (error) {
      errorHandler(error, dispatch, types.getStaffsFailure);
    }
  };
};

export const addStaff = (fields = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: types.addStaffRequest });
      await asreahanApi.post('/users', {
        ...fields,
        passwordConfirm: fields.password,
        isVerified: true,
        role: 'staff'
      });
      dispatch({
        type: types.addStaffSuccess
      });
      toastr.success('Success', 'نقش جدید با موفقیت ایجاد شد');
    } catch (error) {
      errorHandler(error, dispatch, types.addStaffFailure);
    }
  };
};

export const updateStaff = (StaffId, dataToUpdate = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: types.updateStaffRequest });
      const { data } = await asreahanApi.patch(
        `/users/${StaffId}`,
        dataToUpdate
      );
      dispatch({
        type: types.updateStaffSuccess,
        payload: data.data.user
      });
      toastr.success('Success', 'بروز رسانی با موفقیت انجام شد');
    } catch (error) {
      errorHandler(error, dispatch, types.updateStaffFailure);
    }
  };
};

export const deleteStaff = staffId => {
  return async dispatch => {
    try {
      dispatch({ type: types.deleteStaffRequest });
      await asreahanApi.delete(`/users/${staffId}`);
      dispatch({
        type: types.deleteStaffSuccess,
        payload: staffId
      });
    } catch (error) {
      errorHandler(error, dispatch, types.deleteStaffFailure);
    }
  };
};
// USER REVIEWS
export const getReviews = getAll(RESOURCES.REVIEWS);
export const updateReview = updateOne(RESOURCES.REVIEWS);
export const deleteReview = deleteOne(RESOURCES.REVIEWS);
export const createReview = createOne(RESOURCES.REVIEWS);
// USER MESSAGES
export const getMessages = getAll(RESOURCES.MESSAGES);
export const updateMessage = updateOne(RESOURCES.MESSAGES);
export const deleteMessage = deleteOne(RESOURCES.MESSAGES);
// USER INQUIRIES REQUESTS
export const getInquiries = getAll(RESOURCES.INQUIRIES);
export const updateInquiry = updateOne(RESOURCES.INQUIRIES);
export const deleteInquiry = deleteOne(RESOURCES.INQUIRIES);

// USER Credit Sale REQUESTS
export const getCreditSales = getAll(RESOURCES.CREDITS);
export const updateCreditSale = updateOne(RESOURCES.CREDITS);
export const deleteCreditSale = deleteOne(RESOURCES.CREDITS);
