import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import ReduxToastr from 'react-redux-toastr';
import Routes from './routes';
// import * as serviceWorker from './serviceWorker';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './assets/fonts/iransans/iransans.css';
import './assets/sass/main.scss';

ReactDOM.render(
  <Provider store={store}>
    <React.Fragment>
      <PersistGate persistor={persistor}>
        <Routes />
      </PersistGate>
      <ReduxToastr
        timeOut={3000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="bounceIn"
        transitionOut="bounceOut"
        progressBar
      />
    </React.Fragment>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
