import { toastr } from 'react-redux-toastr';
import asreahanApi from 'api/asreahanApi';
import errorHandler from '../errorHandler';
import * as types from '../types';
import { RESOURCES } from '../types/resources';
import { getAll, updateOne } from '../baseActions';

export const getContents = getAll(RESOURCES.CONTENTS);
export const updateContentInfo = updateOne(RESOURCES.CONTENTS);

export const getContent = referTo => {
  return async dispatch => {
    const { request, success, failure } = types.TYPES.contents.GET_ONE;
    try {
      dispatch({ type: request });
      const { data } = await asreahanApi.get(`/contents/refer-to/${referTo}`);
      dispatch({
        type: success,
        payload: data.data.content,
        referTo
      });
    } catch (error) {
      errorHandler(error, dispatch, failure);
    }
  };
};

export const updateQuestions = (referTo, formData = {}, cb) => {
  return async dispatch => {
    try {
      dispatch({ type: types.updateContentQsRequest });
      const { data } = await asreahanApi.patch(
        `/contents/questions/${referTo}`,
        formData
      );
      dispatch({
        type: types.updateContentQsSuccess,
        payload: data.data.questions,
        referTo,
        categoryId: formData.categoryId,
        producerId: formData.producerId
      });
      toastr.success('Success', 'محتوا با موفقیت آپدیت شد');
      if (cb && typeof cb === 'function') cb();
    } catch (error) {
      errorHandler(error, dispatch, types.updateContentQsFailure);
    }
  };
};

export const updateContentCategories = (referTo, formData = {}, cb) => {
  return async dispatch => {
    try {
      dispatch({ type: types.updateContentCatRequest });
      const { data } = await asreahanApi.patch(
        `/contents/categories/${referTo}`,
        formData
      );
      dispatch({
        type: types.updateContentCatSuccess,
        payload: data.data.categories,
        referTo
      });
      toastr.success('Success', 'محتوا با موفقیت آپدیت شد');
      if (cb && typeof cb === 'function') cb();
    } catch (error) {
      errorHandler(error, dispatch, types.updateContentCatFailure);
    }
  };
};

export const uploadContentImage = (id, files) => {
  return async dispatch => {
    try {
      dispatch({ type: types.uploadPhotosRequest });

      const formData = new FormData();
      if (files.photo) formData.append('photo', files.photo[0]);
      if (files.alt) formData.append('alt', files.alt);

      await asreahanApi.patch(`/contents/${id}/photo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch({
        type: types.uploadPhotosSuccess
      });
      toastr.success('Success', 'تصویر با موفقیت ثبت شد');
    } catch (error) {
      errorHandler(error, dispatch, types.uploadPhotosFailure);
    }
  };
};

export const updateCallNumbers = (formData = {}, cb) => {
  return async dispatch => {
    try {
      dispatch({ type: types.updateContentCallNumRequest });
      await asreahanApi.patch(`/contents/call-numbers`, formData);

      toastr.success('Success', 'محتوا با موفقیت آپدیت شد');
      if (cb && typeof cb === 'function') cb();
    } catch (error) {
      errorHandler(error, dispatch, types.updateContentCallNumFailure);
    }
  };
};

export const updateIndexBackground = files => {
  return async dispatch => {
    try {
      dispatch({ type: types.uploadPhotosRequest });

      const formData = new FormData();
      if (files.photo) formData.append('photo', files.photo[0]);
      else return toastr.warning('لطفا ابتدا تصویر را انتخاب نمایید');

      await asreahanApi.patch(`/files/index-bg`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch({
        type: types.uploadPhotosSuccess
      });
      toastr.success('Success', 'تصویر با موفقیت بروز‌ رسانی شد');
    } catch (error) {
      errorHandler(error, dispatch, types.uploadPhotosFailure);
    }
  };
};

export const updateRebarWeightFile = file => {
  return async dispatch => {
    try {
      dispatch({ type: types.uploadPhotosRequest });
      const formData = new FormData();
      formData.append('file', file);
      await asreahanApi.patch(`/files/rebar-weight-table`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch({
        type: types.uploadPhotosSuccess
      });
      toastr.success('Success', 'فایل با موفقیت آپلود شد');
    } catch (error) {
      errorHandler(error, dispatch, types.uploadPhotosFailure);
    }
  };
};

export const updateContentWeightFile = (contentId, file) => {
  return async dispatch => {
    try {
      dispatch({ type: types.uploadPhotosRequest });
      const formData = new FormData();
      formData.append('file', file);
      await asreahanApi.patch(`/contents/${contentId}/upload-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch({
        type: types.uploadPhotosSuccess
      });
      toastr.success('Success', 'فایل با موفقیت آپلود شد');
    } catch (error) {
      errorHandler(error, dispatch, types.uploadPhotosFailure);
    }
  };
};

export const deleteContentWeightFile = (contentId, cb) => {
  return async dispatch => {
    try {
      dispatch({ type: types.uploadPhotosRequest });

      await asreahanApi.delete(`/contents/${contentId}/upload-file`);
      dispatch({
        type: types.uploadPhotosSuccess
      });
      toastr.success('Success', 'فایل با موفقیت حذف شد');
      cb?.();
    } catch (error) {
      errorHandler(error, dispatch, types.uploadPhotosFailure);
    }
  };
};

export const deleteRebarWeightFile = (filename, cb) => {
  return async dispatch => {
    try {
      await asreahanApi.delete(`/files/rebar-weight-table/${filename}`);
      toastr.success('Success', 'فایل با موفقیت حذف شد');
      if (cb && typeof cb === 'function') cb();
    } catch (error) {
      errorHandler(error, dispatch, types.deleteRebarWeightFile);
    }
  };
};

export const uploadContentPhoto = async file => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await asreahanApi.post(`/files/upload-photo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
};
