// import React from 'react';
import { matchPath } from 'react-router-dom';

import { routes as adminRoutes } from 'routes/admin';

export const findComponentForPreload = (path, routes = []) =>
  routes.find(r => matchPath(path, { path: r.path, exact: true }))?.component ||
  null;

export const preloadComponent = path => {
  const component = findComponentForPreload(path, adminRoutes);
  if (component && component.preload) component.preload();
};
