import * as types from 'redux/actions/types';

const INITIAL_PAGINATE = { page: 1, limit: 10 };

const INITIAL_STATE = {
  categoryId: '',
  category: [],
  size: [],
  standard: [],
  producers: [],
  paginate: INITIAL_PAGINATE
};

const _addQuery = (state, action) => {
  const { queryName, value } = action.payload;
  const newValues = [...new Set([...state[queryName], value])];

  return { ...state, paginate: INITIAL_PAGINATE, [queryName]: newValues };
};

const _removeQuery = (state, action) => {
  const { queryName, value } = action.payload;
  const newValues = state[queryName].filter(val => val !== value);

  return { ...state, paginate: INITIAL_PAGINATE, [queryName]: newValues };
};

export const patternsQueryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.setPatternCategoryId:
      return {
        ...INITIAL_STATE,
        categoryId: action.payload
      };
    case types.setQueryPaginate:
      return { ...state, paginate: { ...state.paginate, ...action.payload } };
    case types.addQueryParams:
      return _addQuery(state, action);
    case types.removeQueryParams:
      return _removeQuery(state, action);
    default:
      return state;
  }
};
