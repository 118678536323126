import * as cheerio from 'cheerio';

import asreahanApi from 'api/asreahanApi';
import { p2e, isPositiveInteger } from './general';

/**
 *
 * @param {string} url
 */
export const readAndParseHTML = async (url, includeTax = false) => {
  const key = _getWebsiteKey(url);

  try {
    const response = await asreahanApi.get(`/products/url-to-html?url=${url}`);

    if (response.status !== 200) return;

    const obj = _parser(response.data, key, includeTax);

    return obj;
  } catch (error) {
    console.log('Error while parsing', error);
  }
};

const _WEB_KEYS = {
  ahanonline: 1,
  esfahanahan: 2,
  ahanpakhsh: 3,
  markazeahan: 4
};

const _WEB_NAMES = Object.keys(_WEB_KEYS);

function _getWebsiteKey(url) {
  if (!url || typeof url !== 'string') return;

  for (const n of _WEB_NAMES) {
    if (url.includes(n)) return _WEB_KEYS[n];
  }
}

function _parser(htmlResponse, websiteNum, includeTax) {
  if (!htmlResponse) return;

  const $ = cheerio.load(htmlResponse, { normalizeWhitespace: true });

  let selectors = {};
  let websiteName = '';

  switch (websiteNum) {
    // ahanonline
    case _WEB_KEYS.ahanonline:
      selectors = {
        productName: '.product-list-box header h2',
        date: '.product-list-box header span',
        rows: 'table#productscrol tbody tr.dataTableTr',
        category: "td[data-culomn='products_catt_att']",
        size: "td[data-culomn='size']",
        state: "td[data-culomn='state']",
        thickness: "td[data-culomn='thickness']",
        standard: "td[data-culomn='standard']",
        unit: "td[data-culomn='unit']",
        loading: "td[data-culomn='stock']",
        price: 'td .archiveProducts-productPrice',
        rial: true,
        includeTax
      };
      websiteName = 'آهن آنلاین';
      break;
    // esfahanahan
    case _WEB_KEYS.esfahanahan:
      selectors = {
        productName: '.product_title a',
        date: '.c-product__head__title .c-product__head__date span',
        rows: '.c-product__table table.table tbody tr',
        category: 'td:nth-child(6)',
        size: 'td:nth-child(2)',
        state: 'td:nth-child(6)',
        thickness: 'td:nth-child(3)',
        standard: 'td:nth-child(4)',
        unit: 'td:nth-child(7)',
        loading: 'td:nth-child(8)',
        price: 'td:nth-last-child(3)',
        rial: true,
        includeTax
      };
      websiteName = 'اصفهان آهن';
      break;
    // ahanpakhsh
    case _WEB_KEYS.ahanpakhsh:
      selectors = {
        productName: 'h4.g-title:first-of-type',
        date: 'h4.g-title:first-of-type + .hikashop_products_listing .hikashop_products_table tbody tr:first-child td:nth-child(2) span.small',
        rows: 'h4.g-title:first-of-type + .hikashop_products_listing .hikashop_products_table tbody tr',
        category: '.nav.menu li.current',
        size: "td p[class$='size']",
        state: "td span[class$='pose']",
        thickness: "td p[class$='thikness']",
        standard: "td p[class$='analysis']",
        unit: 'td.hikashop_product_price_row small.small',
        loading: "td p[class$='place']",
        price: 'td.hikashop_product_price_row .hikashop_product_price',
        rial: false,
        includeTax
      };
      websiteName = 'آهن پخش';
      break;
    // markazeahan
    case _WEB_KEYS.markazeahan:
      selectors = {
        productName: '.product-table__title a',
        date: '.product-table__head .product-table__sub b',
        rows: '.table-product tbody tr',
        category: "td[data-title='نوع']",
        size: "td[data-title='سایز']",
        state: "td[data-title='حالت']",
        thickness: "td[data-title='ضخامت(mm)']",
        width: "td[data-title='عرض(m)']",
        standard: "td[data-title='آنالیز']",
        unit: "td[data-title='واحد']",
        loading: "td[data-title='محل بارگیری']",
        price: 'td .table-product__price',
        rial: false,
        includeTax
      };
      websiteName = 'مرکز آهن';
      break;
    default:
      return;
  }

  return { ..._getDetails($, selectors), websiteName };
}

function _getDetails($, selectors) {
  const productName = $(selectors.productName).text().trim();
  const dateParts = $(selectors.date).text().trim().split('\n');
  const date = (dateParts.length > 1 ? dateParts[1] : dateParts[0])
    .replace('بروزرسانی:', '')
    .trim()
    .replace('آخرین بروزرسانی :', '')
    .trim();

  const products = $(selectors.rows).map(function () {
    const category = $(this)
      .find(selectors.category)
      .text()
      ?.split('\n')
      .map(t => t.trim())
      .join(' ')
      .trim();
    const size = $(this).find(selectors.size).text().trim();
    const state = $(this).find(selectors.state).text().trim();
    const thickness = $(this).find(selectors.thickness).text().trim();
    const width = $(this).find(selectors.width).text().trim();
    const standard = $(this).find(selectors.standard).text().trim();
    const unit = $(this).find(selectors.unit).text().trim();
    const loading = $(this).find(selectors.loading).text().trim();

    const price = $(this)
      .find(selectors.price)
      .text()
      .replace('تومان', '')
      .replace(/,/g, '')
      .trim();

    return {
      category,
      size: p2e(size),
      state,
      standard: p2e(standard),
      unit,
      loading,
      price: _formatPrice(price, selectors.rial, selectors.includeTax),
      thickness: p2e(thickness),
      width: p2e(width)
    };
  });

  return {
    productName,
    date,
    products: Array.from(products)
  };
}

function _formatPrice(num = '', rial, includeTax) {
  let price = p2e(num);
  if (!isPositiveInteger(price)) return price;

  if (rial) price /= 10;

  if (includeTax) {
    const tax = Math.round(price * 0.09);
    return +price + tax;
  }

  return price;
}
