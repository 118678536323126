import moment from 'moment-jalaali';
import { e2p } from '.';

export const fillMissingRecords = (
  records = [],
  startDate,
  endDate,
  defaultItem = {}
) => {
  // Return if records is empty
  if (!records.length) return [];
  // Convert array to object (performance purposes!)
  const recordsObj = records.reduce(
    (acc, item) => ((acc[item.createdAt] = item), acc),
    {}
  );
  // console.log('records ', recordsObj);
  // Final result array
  const resultArray = [];
  // Loop and fill the gaps!
  const stopDate = moment(endDate);
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(stopDate)) {
    resultArray.push({
      ...(recordsObj[currentDate.format('YYYY-MM-DD')] || defaultItem),
      createdAt: e2p(currentDate.format('jD jMMMM'))
    });
    // Increment the date
    currentDate = currentDate.add(1, 'day');
  }
  // Return the result
  return resultArray;
};

export const calcStartEndDates = (interval = 1) => {
  const todayDate = moment().format('YYYY-MM-DD');

  const startDate =
    interval === 1
      ? todayDate
      : moment(todayDate).subtract(interval, 'day').format('YYYY-MM-DD');

  return [startDate, todayDate];
};
