import React from 'react';
import { Link } from 'react-router-dom';

import { preloadComponent } from 'utils/helpers';

const PreloadLink = ({ to, component: Component, children, ...rest }) => {
  if (!Component)
    return (
      <Link to={to} onMouseEnter={() => preloadComponent(to)} {...rest}>
        {children}
      </Link>
    );

  return (
    <Component
      to={to}
      component={Link}
      onMouseEnter={() => preloadComponent(to)}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default PreloadLink;
