import asreahanApi from 'api/asreahanApi';
import errorHandler from '../errorHandler';
import * as types from '../types';

export const getAnalyticsBatchReport = (startDate, endDate) => {
  return async dispatch => {
    try {
      dispatch({ type: types.getAnalyticsReportRequest });
      const { data } = await asreahanApi.get(
        `/analytics/batch/${startDate}/${endDate}`
      );
      dispatch({
        type: types.getAnalyticsReportSuccess,
        payload: data.data.reports
      });
    } catch (error) {
      errorHandler(error, dispatch, types.getAnalyticsReportFailure);
    }
  };
};

export const getAnalyticsTotalStats = (startDate, endDate) => {
  return async dispatch => {
    try {
      dispatch({ type: types.getTotalAnalyticsRequest });
      const { data } = await asreahanApi.get(
        `/analytics/totals/${startDate}/${endDate}`
      );
      dispatch({
        type: types.getTotalAnalyticsSuccess,
        payload: data.data.stats
      });
    } catch (error) {
      errorHandler(error, dispatch, types.getTotalAnalyticsFailure);
    }
  };
};
