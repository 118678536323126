import * as types from 'redux/actions/types';
import { deleteData, updateData } from '../utils';

const INITIAL_STATE = {
  data: [],
  count: 0
};

export const callReportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.getCallReportsSuccess:
      return { ...state, ...action.payload };
    case types.addCallReportSuccess:
      return {
        ...state,
        data: [...state.data, action.payload],
        count: state.count + 1
      };
    case types.deleteCallReportSuccess:
      return deleteData(state, action.payload);
    case types.updateCallReportSuccess:
      return updateData(state, action.payload);
    default:
      return state;
  }
};
