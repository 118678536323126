export const fieldParser = value => value.replace(/,/g, '').replace(/-/g, '');

export const isPositiveInteger = n => n >>> 0 === parseFloat(n);

export const e2p = s => `${s || ''}`.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d]);

export const p2e = s =>
  `${s || ''}`.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));

export const formatNumber = num => {
  if ((!num && num !== 0) || !Number.isFinite(+num)) return '---';

  return new Intl.NumberFormat('fa-IR').format(+num);
};

export const getPriceChangeColor = (num = 0) =>
  num === 0
    ? 'var(--color-violet)'
    : num > 0
    ? 'var(--color-red)'
    : 'var(--color-green)';
