import { toastr } from 'react-redux-toastr';
import asreahanApi from 'api/asreahanApi';
import errorHandler from '../errorHandler';
import history from 'history.js';
import * as types from '../types';

export const addFactoryPrice = formValues => {
  return async dispatch => {
    try {
      dispatch({ type: types.addFactoryPriceRequest });
      await asreahanApi.post('/factories-price', formValues);
      dispatch({
        type: types.addFactoryPriceSuccess
      });
      toastr.success('Success', 'تولید‌کننده جدید با موفقیت به لیست اضافه شد');
      history.push('/admin/producers-price?reload=1');
    } catch (error) {
      errorHandler(error, dispatch, types.addFactoryPriceFailure);
    }
  };
};

export const updateFactoryCurrentPrice = (id, newPrice) => {
  return async dispatch => {
    try {
      dispatch({ type: types.updateFactoryCurrentPriceRequest });
      const { data } = await asreahanApi.patch(`/factories-price/${id}/updatePrice`, {
        currentPrice: newPrice
      });
      dispatch({
        type: types.updateFactoryCurrentPriceSuccess,
        payload: data.data.factoryPrice
      });
      toastr.success('Success', 'قیمت محصول با موفقیت بروز‌رسانی شد');
    } catch (error) {
      errorHandler(error, dispatch, types.updateFactoryCurrentPriceFailure);
    }
  };
};

export const updateFactoryPriceData = (id, formValues) => {
  return async dispatch => {
    try {
      dispatch({ type: types.updateFactoryDataRequest });
      const { data } = await asreahanApi.patch(
        `/factories-price/${id}`,
        formValues
      );
      dispatch({
        type: types.updateFactoryDataSuccess,
        payload: data.data.factoryPrice
      });
      toastr.success('Success', 'اطلاعات با موفقیت بروز‌رسانی شد');
    } catch (error) {
      errorHandler(error, dispatch, types.updateFactoryDataFailure);
    }
  };
};

export const deleteFactoryPrice = id => {
  return async dispatch => {
    try {
      dispatch({ type: types.deleteFactoryPriceRequest });
      await asreahanApi.delete(`/factories-price/${id}`);
      dispatch({
        type: types.deleteFactoryPriceSuccess,
        payload: id
      });
    } catch (error) {
      errorHandler(error, dispatch, types.deleteFactoryPriceFailure);
    }
  };
};

const INITIAL_PARAMS = {
  select: '-priceHistory',
  limit: 100
};
export const getFactoriesPriceList = (params = INITIAL_PARAMS) => {
  return async dispatch => {
    try {
      dispatch({ type: types.getFactoriesPricesRequest });
      const { data } = await asreahanApi.get('/factories-price', {
        params
      });
      dispatch({
        type: types.getFactoriesPricesSuccess,
        payload: { data: data.data.factoriesprices, count: data.totalDocs }
      });
    } catch (error) {
      errorHandler(error, dispatch, types.getFactoriesPricesFailure);
    }
  };
};
