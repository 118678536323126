import React from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

import { logout } from 'redux/actions';
import AdminItems from './AdminItems';
import UserItems from './UserItems';
import { useStyles } from './styles';

const SidebarList = ({ currentUser, logout }) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {currentUser?.role === 'admin' || currentUser?.role === 'staff' ? (
        <AdminItems user={currentUser} />
      ) : (
        <UserItems />
      )}
      <ListItem className={classes.item} button onClick={logout}>
        <ListItemIcon className={classes.icon}>
          <ExitToAppOutlinedIcon />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="خروج" />
      </ListItem>
    </List>
  );
};

const mapStateToProps = ({ user: { currentUser } }) => ({ currentUser });

export default connect(mapStateToProps, { logout })(SidebarList);
