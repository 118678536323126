import asreahanApi from 'api/asreahanApi';
import errorHandler from '../errorHandler';
import * as types from '../types';
import { setLocalStorageItem, getLocalStorageItem } from 'utils/helpers';

export const getCategories = () => {
  return async dispatch => {
    try {
      dispatch({ type: types.getCategoriesRequest });
      // First check the localStorage
      const categories = getLocalStorageItem('_categories');
      if (categories)
        return dispatch({
          type: types.getCategoriesSuccess,
          payload: categories
        });
      // Else send request to our API
      const { data } = await asreahanApi.get('/categories', {
        params: {
          select: 'name,enName,specifications',
          sort: '-priority'
        }
      });
      dispatch({
        type: types.getCategoriesSuccess,
        payload: data.data.categories
      });
      // Save new categories to the localStorage
      setLocalStorageItem('_categories', data.data.categories);
    } catch (error) {
      errorHandler(error, dispatch, types.getCategoriesFailure);
    }
  };
};
